import React from "react";
import logo from "../../assets/logo.svg";
import facebook from "../../assets/face.svg";
import instagram from "../../assets/insta.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";

function Footer() {
  const transfers = [
    "wise",
    "transfergo",
    "profee",
    "paysend",
    "skrill",
    "revolut",
    "zen",
    "wasternunion",
  ];
  const pays = ["about_us", "feedback", "money_transfers"];
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const { isMobile, isSmallTablet, isMediumTablet, isTablet, isSmallDesktop } =
    useWindowSize();
  return (
    <div className="bg-[#41556E]  2xl:px-40 px-4 xl:px-36 lg:px-28 xl:py-14 py-8  ">
      {!isMobile && !isSmallTablet && !isMediumTablet ? (
        <div className="flex flex-row justify-between containerBig w-full mb-16 ">
          <div className="flex flex-col items-start">
            <img src={logo} alt="paytoua" />
            <span className="text-white font-normal text-[13px] mt-4">
              info@paytoua.com
            </span>
            <div className="flex flex-row mt-4">
              <div className="mr-2">
                {" "}
                <Link
                  to={
                    "https://www.facebook.com/people/Paytouacom/61560372960077/"
                  }
                  target="_blank"
                >
                  <img src={facebook} alt="facebook" />
                </Link>
              </div>
              <Link
                to={"https://www.instagram.com/paytoua_com/"}
                target="_blank"
              >
                <img src={instagram} alt="instagram" />
              </Link>
            </div>
            <Link to={`/termsofservice?${searchParams}`} className="mt-10">
              <span className="text-white font-normal text-[13px] mt-20 mb-2">
                {t("terms_of_Service")}{" "}
              </span>
            </Link>

            <br />
            <Link to={`/confidence?${searchParams}`}>
              <span className="text-white font-normal text-[13px]">
                {t("privacyPolicy")}{" "}
              </span>
            </Link>
          </div>
          <div className="flex flex-col items-start  w-[30%]">
            <span className="text-white font-normal text-base">
              {t("partnerInformation")}
            </span>
            <span className="text-white font-normal text-base opacity-80 text-left ">
              {t("partnerInformation_text")}
            </span>
          </div>
          <div className="flex flex-col items-start">
            <span className="text-white font-normal text-base">
              {t("money_transfers")}
            </span>
            {transfers.map((item, index) => {
              return (
                <span
                  key={index}
                  className="text-white font-normal text-base opacity-80 text-left "
                >
                  {item}
                </span>
              );
            })}
          </div>
          <div className="flex flex-col items-start">
            <span className="text-white font-normal text-base">
              paytoua.com
            </span>
            {pays.map((item, index) => {
              return (
                <Link to={`/${item}?${searchParams}`}>
                  <span
                    key={index}
                    className="text-white font-normal text-base opacity-80 text-left "
                  >
                    {t(item)}
                  </span>{" "}
                </Link>
              );
            })}
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-center items-center mb-5">
          <div className="flex flex-col items-center">
            <img src={logo} alt="paytoua" />
            <span className="text-white font-normal text-[13px] mt-4">
              info@paytoua.com
            </span>
            <div className="flex flex-row mt-4">
              <div className="mr-2">
                {" "}
                <Link
                  to={
                    "https://www.facebook.com/people/Paytouacom/61560372960077/"
                  }
                  target="_blank"
                >
                  <img src={facebook} alt="facebook" />
                </Link>
              </div>
              <Link
                to={"https://www.instagram.com/paytoua_com/"}
                target="_blank"
              >
                <img src={instagram} alt="instagram" />
              </Link>
            </div>
          </div>
          <div className="flex flex-row w-full justify-between mt-7">
            <div className="flex flex-col items-start">
              <span className="text-white font-normal text-base">
                {t("money_transfers")}
              </span>
              {transfers.map((item, index) => {
                return (
                  <span
                    key={index}
                    className="text-white font-normal text-base opacity-80 text-left "
                  >
                    {item}
                  </span>
                );
              })}
            </div>
            <div className="flex flex-col items-start">
              <span className="text-white font-normal text-base">
                paytoua.com
              </span>
              {pays.map((item, index) => {
                return (
                  <Link to={`/${item}?${searchParams}`}>
                    <span
                      key={index}
                      className="text-white font-normal text-base opacity-80 text-left "
                    >
                      {t(item)}
                    </span>
                  </Link>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col items-start  w-full mt-7">
            <span className="text-white font-normal text-base">
              {t("partnerInformation")}
            </span>
            <span className="text-white font-normal text-base opacity-80 text-left ">
              {t("partnerInformation_text")}
            </span>

            <Link
              to={`/termsofservice?${searchParams}`}
              className="w-full mt-5 "
            >
              <span className="text-white font-normal text-base opacity-80">
                {t("terms_of_Service")}{" "}
              </span>
            </Link>

            <Link to={`/confidence?${searchParams}`} className="w-full mt-4">
              <span className="text-white font-normal text-base opacity-80 text-left ">
                {t("privacyPolicy")}{" "}
              </span>
            </Link>
          </div>
        </div>
      )}
      <span className="text-white font-normal text-base opacity-80 text-left ">
        © 2024 paytoua.com. All Rights Reserved.
      </span>

      <div className="absolute h-0 z-[-1]">
        <Link to={`/termsofservice?lang=gb`} className="absolute text-[#41556E] w-0 h-0">
          dd
        </Link>
        <Link to={`/termsofservice?lang=ru`} className="absolute text-[#41556E] w-0 h-0">
          dd
        </Link>
        <Link to={`/confidence?lang=ru`} className=" absolute text-[#41556E] w-0 h-0">
          dd
        </Link>
        <Link to={`/confidence?lang=gb`} className="absolute text-[#41556E] w-0 h-0">
          dd
        </Link>
        <Link to={`/about_us?lang=ru`} className=" absolute text-[#41556E] w-0 h-0">
          dd
        </Link>
        <Link to={`/about_us?lang=gb`} className="absolute text-[#41556E] w-0 h-0">
          dd
        </Link>
        <Link to={`/feedback?lang=ru`} className="absolute text-[#41556E] w-0 h-0">
          d
        </Link>
        <Link to={`/feedback?lang=gb`} className=" absolute text-[#41556E] w-0 h-0">
          -
        </Link>
        <Link to={`/?lang=ru`} className="absolute text-[#41556E] h-0 w-0">
        -
        </Link>
        <Link to={`/?lang=gb`} className="absolute text-[#41556E] h-0 w-0">
          -
        </Link>
        <Link to={`/methods?lang=ru`} className="absolute text-[#41556E] w-0 h-0">
          -
        </Link>
        <Link to={`/methods?lang=gb`} className="absolute text-[#41556E] w-0 h-0">
        -
        </Link>
      </div>
    </div>
  );
}

export default Footer;
