import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import "./error.css";
import "../../index.css";

import Header from "../../components/header/Header";

import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";

function NotFound() {
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paytoua.com/" />
        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />
        <meta property="og:title" content={t("metaTitle")} />
        <meta name="prerender-status-code" content="404"></meta>
        <meta
          property="og:image"
          content="https://paytoua.com/ff93cf53-f3fb-4232-996c-8b57d3113a12.jpg"
        />
        <meta property="og:description" content={t("metadescrpt")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
        <title>404 error</title>
      </Helmet>

      <div className="header-background ">
        <div className="header_container pt-1 pb-1 ">
          <Header />
        </div>
      </div>

      <div className="containerBig pl-2 pr-2">
        <div className="error">
          <h1>404</h1>
          <p>Oops! That page can't be found.</p>
          <span>
            We're really sorry but we can't seem to find the page you were
            looking for.
          </span>
          <Link to="/">
            <button>Back the Home page</button>
          </Link>
        </div>
      </div>
    </>
  );
}

export default NotFound;
