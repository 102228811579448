import Header from "../../components/header/Header";
import React, { useEffect } from "react";
import "./about.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
function About() {
  const { t } = useTranslation();
  ReactGA.send({
    hitType: "pageview",
    page: "https://paytoua.com/about_us",
    title: "About_us",
  });
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paytoua.com/" />
        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />
        <meta property="og:title" content={t("metaTitle")} />
        <meta
          property="og:image"
          content="https://paytoua.com/ff93cf53-f3fb-4232-996c-8b57d3113a12.jpg"
        />
        <meta property="og:description" content={t("metadescrpt")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
        <title>{t("about_us")}</title>
        <link
          rel="alternate"
          hreflang="ru"
          href="https://paytoua.com/about_us?lang=ru"
        />
        <link
          rel="canonical"
          hreflang="uk"
          href="https://paytoua.com/about_us?lang=uk"
        />
        <link
          rel="alternate"
          hreflang="en"
          href="https://paytoua.com/about_us?lang=gb"
        />
      </Helmet>
      <div className="w-full min-h-screen flex flex-col">
        <div className="header-background ">
          <div className="header_container pt-1 pb-1 ">
            <Header />
          </div>
        </div>

        <div className="flex-grow first_style flex flex-col items-center bg-gray-100 p-4">
          <div className="max-w-1350 w-full  px-4 sm:p-8 mt-8 mb-8">
            <p className="text-gray-text  mb-4 text-left">
              {t("about_Service_date")}
            </p>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_Service_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_us")}
            </h2>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_one_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_two")}
            </h2>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_two_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12  text-center">
              {t("about_title_three")}
            </h2>
            <h2 className="text-2xl font-semibold  mb-6 text-center">
              {t("about_title_three_text")}
            </h2>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_three_text_two")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_four")}
            </h2>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_four_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_five")}
            </h2>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_five_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_six")}
            </h2>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_six_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_seven")}
            </h2>
            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_seven_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_eight")}
            </h2>
            <span className="flex text-gray-text mySpan mb-2 text-left ">
              <p className=" font-semibold mb-2 mr-3 text-[#000] whitespace-nowrap	 text-left">
                {t("about_title_eight_span")}
              </p>{" "}
              {t("about_title_eight_text")}
            </span>

            <span className="flex text-gray-text mySpan  mb-2 text-left ">
              <p className=" font-semibold mr-3 mb-2 text-[#000] whitespace-nowrap	 text-left">
                {t("about_title_eight_spantwo")}
              </p>{" "}
              {t("about_title_eight_text_two")}
            </span>
            <span className="flex text-gray-text mySpan  mb-2 text-left ">
              <p className=" font-semibold mb-2 mr-3 text-[#000] whitespace-nowrap	 text-left">
                {t("about_title_eight_spanthree")}{" "}
              </p>{" "}
              {t("about_title_eight_text_three")}
            </span>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_nine")}
            </h2>
            <span className="flex text-gray-text mySpan mb-2 text-left ">
              <p className=" font-semibold mb-2 mr-3 text-[#000] whitespace-nowrap	 text-left">
                {t("about_title_nine_span")}{" "}
              </p>{" "}
              {t("about_title_nine_text")}
            </span>
            <span className="flex text-gray-text mySpan  mb-2 text-left ">
              <p className=" font-semibold mb-2 mr-3 text-[#000] whitespace-nowrap	 text-left">
                {t("about_title_nine_spantwo")}{" "}
              </p>{" "}
              {t("about_title_nine_text_two")}
            </span>
            <span className="flex text-gray-text mySpan mb-2 text-left ">
              <p className=" font-semibold mb-2 mr-3 text-[#000] whitespace-nowrap	 text-left">
                {t("about_title_nine_spanthree")}{" "}
              </p>{" "}
              {t("about_title_nine_text_three")}
            </span>

            <p className="text-gray-text  mb-4 text-left">
              {t("about_title_ten_text")}
            </p>
            <h2 className="text-2xl font-semibold mt-12 mb-6 text-center">
              {t("about_title_last")}
            </h2>
            <p className=" font-semibold mb-4 text-left">
              {t("about_title_last_text")}
            </p>
            <p className=" font-semibold mb-4 text-left">
              {t("about_title_last_text_two")}
            </p>
            <p className="font-semibold  mb-4 text-left">
              {t("about_title_last_text_three")}{" "}
              <Link to={"mailto:info@paytoua.com"}>info@paytoua.com</Link>
            </p>
            <p className="font-semibold  mb-4 text-left">
              {t("about_title_last_text_four")}{" "}
              <Link to={"tel:421 950 348416"}>+421 950 348416</Link>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
