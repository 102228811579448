import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Squircle } from "corner-smoothing";
import "./styles.css";
import { Button } from "@material-tailwind/react";
import useWindowSize from "../../hooks/useWindowSize";
import Slider from "../../components/slider/Slider";
import { Link, useSearchParams } from "react-router-dom";

export const Reviews = ({ reviews }) => {
  const { t } = useTranslation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [array, setArray] = useState(
    reviews.length <= 6 ? reviews : reviews.slice(0, 6)
  );
  const { isMobile, isSmallTablet } = useWindowSize();
  const returnData = () => {
    return reviews.map((item, _) => {
      // console.log("item.background",item.background, item)
      return {
        id: item.id,
        bgColor: item.background,
        img: `https://admin.paytoua.com/${item?.img}`,
        text: item.text,
        isTransfer: true,
        link: `blog/${item?.link?.replace(/\s+/g, "_")}/${
          item?.id
        }?${searchParams}`,
      };
    });
  };

  return (
    <div className="flex flex-col items-center w-full rewieSlider">
      <div className=" flex flex-col items-center sm:w-[390px] w-[300px]">
        <p className=" font-semibold sm:text-[32px] text-2xl ">
          {t("reviews_of_money_transfer_services")}
        </p>
        <p className="mt-2 mb-10 text-[#213E4A] opacity-60 text-base font-normal">
          {t("world_leaders_in_international_money_transfers")}{" "}
        </p>
      </div>
      {isMobile || isSmallTablet ? (
        <Slider activeSlide={1} data={returnData()} />
      ) : (
        <>
          <div className="grid grid-cols-1  md:grid-cols-2 xl:grid-cols-3 lg:grid-cols-2 2xl:grid-cols-3  gap-5">
            {array.map((item) => {
              return (
                <Link
                  to={`/blog/${item?.link?.replace(/\s+/g, "_")}/${
                    item?.id
                  }?${searchParams}`}
                  onClick={() => {}}
                >
                  <Squircle
                    className="w-auto forShadow h-[400px] py-7 px-6 flex flex-col justify-between rewiew_box"
                    style={{ backgroundColor: item.background }}
                  >
                    <div className="w-full flex items-center justify-center flex-grow ">
                      <img
                        className=" w-full max-h-[100px] "
                        src={`https://admin.paytoua.com/${item?.img}`}
                      />
                    </div>
                    <div
                      className="truncate-multiline "
                      dangerouslySetInnerHTML={{ __html: item?.text }}
                    ></div>
                  </Squircle>
                </Link>
              );
            })}
          </div>
          {
            <Button
              onClick={(e) => {
                setArray(
                  array.length !== reviews.length
                    ? reviews
                    : reviews.slice(0, 6)
                );
              }}
              className={
                "bg-[black] rounded-[20px] text-base font-semibold h-12 w-[340px] text-white  mt-10"
              }
            >
              {array.length !== reviews.length ? t("show_all") : t("show_less")}
            </Button>
          }
        </>
      )}
    </div>
  );
};
