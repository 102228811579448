import Header from "../../components/header/Header";
import React, { useEffect } from "react";
import "../termsOfService/termsofservice.css";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
function PoliticConfidense() {
  ReactGA.send({
    hitType: "pageview",
    page: "https://paytoua.com/confidence",
    title: "confidence",
  });
  const { t } = useTranslation();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paytoua.com/" />
        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />
        <meta property="og:title" content={t("metaTitle")} />
        <meta
          property="og:image"
          content="https://paytoua.com/ff93cf53-f3fb-4232-996c-8b57d3113a12.jpg"
        />
        <meta property="og:description" content={t("metadescrpt")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
        <title>{t("privacyPolicy")}</title>
        <link
          rel="alternate"
          hreflang="ru"
          href="https://paytoua.com/confidence?lang=ru"
        />
        <link
          rel="canonical"
          hreflang="uk"
          href="https://paytoua.com/confidence?lang=uk"
        />
        <link
          rel="alternate"
          hreflang="en"
          href="https://paytoua.com/confidence?lang=gb"
        />
      </Helmet>

      <div className="w-full min-h-screen flex flex-col">
        <div className="header-background ">
          <div className="header_container pt-1 pb-1 ">
            <Header />
          </div>
        </div>

        <div className="flex-grow first_style flex flex-col items-center bg-gray-100 p-4">
          <div className="max-w-1350 w-full px-4 sm:p-8 mt-8 mb-8">
            <h1 className="text-3xl first_style font-semibold mb-12 text-center">
              {t("privacyPolicy")}
            </h1>
            <p className="text-gray-text mb-4 text-left">{t("politic_data")}</p>
            <p className="text-gray-text mb-4 text-left">
              {t("politic_data_text")}{" "}
            </p>

            <p className="text-gray-point mt-8 mb-4 text-left">
              {t("politic_title_one_text")}
            </p>
            <p className="text-gray-point mt-4 mb-4 text-left">
              {t("politic_title_two_text")}
            </p>

            <ul>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_two_text_one")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_two_text_two")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_two_text_three")}
              </li>
            </ul>

            <p className="text-gray-point mt-4 mb-4 text-left">
              {t("politic_title_three")}
            </p>

            <ul>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_three_text")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_three_text_two")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_three_text_three")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_three_text_four")}
              </li>
            </ul>
            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_four")}
            </p>
            <ul>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_four_text")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_four_text_two")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_four_text_three")}
              </li>
            </ul>

            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_five")}
            </p>
            <ul>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_five_text")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_five_text_two")}
              </li>
            </ul>
            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_six")}
            </p>
            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_seven")}
            </p>
            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_eight")}
            </p>

            <ul>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_eight_text_one")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_eight_text_two")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_eight_text_three")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_eight_text_four")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_eight_text_five")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_eight_text_six")}
              </li>
              <li className="text-gray-text mb-4 ml-7 text-left">
                {t("politic_title_eight_text_seven")}
              </li>
            </ul>

            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_eight_text")}
            </p>

            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_nine_text")}
            </p>
            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_ten_text")}
            </p>
            <p className="text-gray-point mb-4 text-left">
              {t("politic_title_last_text")}
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default PoliticConfidense;
