import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";
import { Button } from "@material-tailwind/react";
import SelectCountry from "../../components/selectCountry/SelectCountry";
import UA from "../../assets/3x2/UA.svg";
import {
  selectCountries,
  selectCountryFrom,
  selectCurrencyFrom,
  selectCurrencyTo,
  selectIsSender,
  selectSmartPay,
  selectValue,
} from "../../store/selectors/main-selector";
import {
  setCurrencyFrom,
  setCurrencyTo,
  setIsSender,
  setSelectedCountryFrom,
  setSmartPay,
  setValue,
} from "../../store/actions/main-action";
import { ReactComponent as Exchange } from "../../assets/Exchange.svg";
import SelectCurrency from "../../components/selectCurrency/SelectCurrency";
import ButtonGroupComponent from "../../components/buttonGroup/ButtonGroup";
import SmartPay from "../home/SmartPay";
import top from "../../assets/top.svg";
import RightLine from "../../assets/RightLine.svg";
import DownUp from "../../assets/DownUp.svg";
import { useSearchParams } from "react-router-dom";
import "../../index.css";

function CalculateMobile({ setSelectedIndex, handleClick }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const countries = useSelector(selectCountries);
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const currencyFrom = useSelector(selectCurrencyFrom);
  const currencyTo = useSelector(selectCurrencyTo);
  const isSender = useSelector(selectIsSender);
  const smartPay = useSelector(selectSmartPay);
  const value = useSelector(selectValue);
  const [isMore, setIsMore] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
 
  return (
    <div
      className="w-full calculatorSmall border-[2px] border-[white] rounded-[40px] p-[6%] relative mt-9 "
      style={{ backgroundColor: "rgba(126, 182, 234, 0.5)" }}
    >
      {isMore ? (
        <>
          <SelectCountry
            label={t("sender_country")}
            list={countries}
            selected={ searchParams.get("from_country")
              ? {[searchParams.get("from_country")] : countries[searchParams.get("from_country")] }: selectedCountryFrom
}
            setSelected={(value) => {
              dispatch(setSelectedCountryFrom(value));
              searchParams.set(
                "from_country",
                Object.keys(value)[0].toUpperCase()
              );
              setSearchParams(searchParams, { replace: true });
              // window.localStorage.setItem("countryFrom", JSON.stringify(value));
            }}
          />
          {/* <div className=" absolute top-[18.5%] right-[40%] w-[36px] h-[36px] rounded-[50%] border border-[#9C9C9C] bg-[#EFF1FBCC] flex justify-center items-center z-10">
            <Exchange />
          </div> */}
          <SelectCountry
            label={t("recipient_country")}
            list={countries}
            selected={{ UA: { name: `${t("ukraine")} ` } }}
            disabled={true}
          />
          <div className="flex flex-row  mt-8  space-x-1">
            <div className="bg-[white] w-[70%] flex flex-row pl-4 justify-between rounded-[20px] relative flex-grow">
              {/* <ButtonGroupComponent
                isSender={isSender}
                setIsSender={(value) => {
                  dispatch(setIsSender(value));
                  searchParams.set("isSender", value);
                  setSearchParams(searchParams, { replace: true });
                  // window.localStorage.setItem("isSender", value);
                }}
              /> */}
              <input
                type="number"
                className="w-[55%] outline-none text-[#0E0F0C] text-lg font-semibold"
                value={value}
                onChange={(event) => {
                  dispatch(setValue(event.target.value));
                  // window.localStorage.setItem("value", value);
                  searchParams.set("value", event.target.value);
                  setSearchParams(searchParams, { replace: true });
                }}
              />
              <div>
                <SelectCurrency
                  list={
                    isSender
                      ? selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                          ?.from_currency ?? []
                      : selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                          ?.to_currency ?? []
                  }
                  selected={isSender ? currencyFrom : currencyTo}
                  setSelected={(value) => {
                    if (isSender) {
                      dispatch(setCurrencyFrom(value));
                      searchParams.set("currency_from", value);
                      setSearchParams(searchParams, { replace: true });
                    } else {
                      dispatch(setCurrencyTo(value));
                      searchParams.set("currency_to", value);
                      setSearchParams(searchParams, { replace: true });
                    }
                  }}
                />
              </div>
            </div>
            <div className="relative">
              <span className="text-[white] ml-4 mb-1 font-semibold	text-xs	 absolute z-10 left-0 top-[-20px]">
                {!isSender ? t("send") : t("get")}
              </span>

              <SelectCurrency
                list={
                  !isSender
                    ? selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                        ?.from_currency ?? []
                    : selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                        ?.to_currency ?? []
                }
                selected={!isSender ? currencyFrom : currencyTo}
                setSelected={(value) => {
                  if (!isSender) {
                    dispatch(setCurrencyFrom(value));
                    searchParams.set("currency_from", value);
                    setSearchParams(searchParams, { replace: true });
                  } else {
                    dispatch(setCurrencyTo(value));
                    searchParams.set("currency_to", value);
                    setSearchParams(searchParams, { replace: true });
                  }
                }}
              />
            </div>
          </div>
          <SmartPay
            smartPay={smartPay}
            setSmartPay={(value) => {
              dispatch(setSmartPay(value));
              value.length > 0
                ? searchParams.set("smartPay", JSON.stringify(value))
                : searchParams.delete("smartPay");
              setSearchParams(searchParams, { replace: true });
              // window.localStorage.setItem("smartPay", JSON.stringify(value));
            }}
          />
          <Button
            onClick={() => {
              window.localStorage.setItem("toMethod", 0);
              setSelectedIndex(0);
              handleClick(0);
            }}
            className="w-full myButton rounded-[20px] text-lg   bg-[black] text-[white] flex flex-row items-center justify-center py-3 mt-10"
          >
            {t("compare")}
            <IoIosSearch color="white" className="pl-2" size={30} />
          </Button>
          <span className="text-white font-semibold	text-xs	mt-2">
            {t("it_is_not_necessary_to_fill")}
          </span>

          <div className="w-full items-center justify-center flex mt-2 notShowOnPage">
            <div
              onClick={() => setIsMore(false)}
              className="bg-[#86bbea] h-8 w-8 flex justify-center items-center rounded-[50%] cursor-pointer"
            >
              <img src={top} alt="top" />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-row justify-between w-full">
            <div className="flex items-center">
              <img
                alt={Object.keys(selectedCountryFrom)[0]?.toUpperCase()}
                width={34}
                height={24}
                className={"mr-2 rounded-[50%]"}
                src={require(`../../assets/3x2/${
                  Object.keys(selectedCountryFrom)[0]?.toUpperCase() ||
                  searchParams.get("from_country") || 'AT'
                }.svg`)}
                
              />
              <p className="text-[white] text-base">
                {value} {currencyFrom}
              </p>
              <img
                src={RightLine}
                alt="Right arrow"
                className="rightArrow mx-5 "
              />
              <img
                alt={"UA"}
                width={34}
                height={24}
                className={"mr-2 rounded-[50%]"}
                src={UA}
              />
              <p className="text-[white] text-base">{currencyTo}</p>
            </div>
            <div
              onClick={() => setIsMore(true)}
              className="bg-[#86bbea] h-8 w-8 flex justify-center items-center rounded-[50%] cursor-pointer"
            >
              <img src={DownUp} alt="DownUp" />
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default CalculateMobile;
