import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Accordion from "../../components/accardion/Accordion";

function Answers() {
  const { t } = useTranslation();

  const [isShowIndex, setIsShowIndex] = useState();
  const answers = [
    {
      title: t("limits_on_sending_money_to_Ukraine"),
      subTitle: t("limits_on_sending_money_subtitle"),
    },
    {
      title:t( "Can_I_send_money"), 
      subTitle: t( "Can_I_send_money_subtitle"),
    },
    {
      title: t("my_European_bank_to_transfer_money"),
      subTitle: t("my_European_bank_to_transfer_money_subtitle"),
    },
    {
      title: t("most_favorable_exchange_rate"),
      subTitle: t("most_favorable_exchange_rate_subtitle"),
    },
    {
      title:t( "using_Western_Union"),
      subTitle: t( "using_Western_Union_subtitle"),
    },

    {
      title: t("your_friend_receives_cash"),
      subTitle: t("your_friend_receives_cash_subtitle"),
    },
    {
      title: t("Which_app_is_best"),
      subTitle: t("Which_app_is_best_subtitle"),
    },
    
   
   
  
 
   
  ];
  // fontSize: 32,
  // fontWeight: 600,
  // textAlign: "center" as "center",
  // color: Colors.darkBlue,
  return (
    <div className="">
      <div className="flex flex-col justify-center mb-14 ">
        <span className=" text-[32px] font-semibold sm:text-center text-left text-[#011A3F]">{t('answers_to_frequently_asked_questions')}</span>
      </div>
      {answers.map((item, index) => {
        return (
          <Accordion
            key={index}
            title={item.title}
            subTitle={item.subTitle}
            onClick={() => {
              setIsShowIndex(index === isShowIndex ? null : index);
            }}
            isShow={index === isShowIndex}
          />
        );
      })}
    </div>
  );
}

export default Answers;
