import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Squircle } from "corner-smoothing";
import "./styles.css";
import { Link,useSearchParams } from "react-router-dom";

export const Regulcar = ({ regular }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  return (
    <div className="flex flex-col items-center mt-20 ">
      <div className=" flex flex-col items-center">
        <p className=" font-semibold text-[32px] mb-10 ">{t("all_regular")}</p>
      </div>

      <>
        <div className="grid grid-cols-2 mb-10  md:grid-cols-2 xl:grid-cols-4 lg:grid-cols-3 2xl:grid-cols-4  gap-4">
          {regular.map((item) => {
         
            return (
              <Link
                key={item?.id}
                to={`/blog/${item?.link?.replace(/\s+/g, "_")}/${item?.id}?${searchParams}`}
              >
                <Squircle
               
                  className="w-auto forShadow maxHeigtforRevie max-h-[380px] pt-2 px-2 pb-4 bg-[#F7F7F9] flex flex-col justify-start  shadow-lg "
                >
                  <Squircle
                    cornerRadius={60}
                    cornerSmoothing={1}
                    className="w-full flex items-center justify-center object-cover"
                  >
                    <img
                      className=" w-full  h-[200px] "
                      src={`https://admin.paytoua.com/${item.img}`}
                    />
                  </Squircle>
                  <div
                    className="regular-truncate-multiline mt-3"
                    dangerouslySetInnerHTML={{ __html: item?.text }}
                  ></div>
                </Squircle>
              </Link>
            );
          })}
        </div>
      </>
    </div>
  );
};
