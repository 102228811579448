import ru from "./ru.json";
import gb from "./gb.json";
import uk from "./uk.json";

const translation = {
  gb,
  uk,
  ru,

 
};
export default translation;
