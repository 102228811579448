import Western from "../../assets/Western.svg";
import KoronaPay from "../../assets/KoronaPay.svg";
import Profee from "../../assets/profee.svg";
import Zen from "../../assets/Zen.svg";
import Transfergo from "../../assets/Transfergo.svg";
import Skrill from "../../assets/Skrill.svg";
import Paysend from "../../assets/Paysend.svg";
import Revoult from "../../assets/revoult.svg";
import Wise from "../../assets/Wise.svg";
import ZenPrivat from "../../assets/ZenPrivat.svg";

export const returnColor = (site) => {
  switch (site) {
    case "westernUnion.com":
      return "#FFF4DF";
    case "koronapay.com":
      return "#D7EEF8";
    case "profee.com":
      return "#ECECFB";
    case "zen.com":
      return "#EFEFEF";
    case "transfergo.com":
      return "#F7F4D0";
    case "skrill.com":
      return "#F5E8F2";
    case "paysend.com":
      return "#F0E6FE";
    case "revolut.com":
      return "#D7EEF8";
    default:
      return "#DAECCB";
  }
};
export const returnImage = (site) => {
  switch (site) {
    case "westernUnion.com":
      return Western;
    case "koronapay.com":
      return KoronaPay;
    case "profee.com":
      return Profee;
    case "zen.com":
      return Zen;
    case "transfergo.com":
      return Transfergo;
    case "skrill.com":
      return Skrill;
    case "paysend.com":
      return Paysend;
    case "revolut.com":
      return Revoult;
    case "zen.comprivat":
      return ZenPrivat;
    default:
      return Wise;
  }
};
export const returnPrefix = (prefix, title_smart, t) => {
  switch (prefix) {
    case "PB":
      return "+ PrivatBank ";
    case "MONO":
      return "+ monobank ";
    case "Ger":
      return `+ ${t("from_Germany")} ` + (title_smart ? title_smart : "");
    case "Lit":
      return `+ ${t("from_Lithuania")} ` + (title_smart ? title_smart : "");
    default:
      return " ";
  }
};

export const returnName = (item, t) => {
  switch (item) {
    case "bank":
      return t("to_a_bank_account");

    case "card":
      return t("on_the_card");

    default:
      return t("receipt_in_cash");
  }
};

export const returnWiseName = (item, t) => {
  switch (item) {
    case "bank":
      return t("on");

    case "card":
      return t("by_card");

    default:
      return t("receipt_in_cash");
  }
};
