/* eslint-disable no-undefined */
import { useEffect, useState } from 'react';

const useWindowSize = () => {
  const [size, setSize] = useState([undefined, undefined]);
  const [isDesktop, setIsDesktop] = useState(undefined);
  const [isLargeDesktop, setIsLargeDesktop] = useState(undefined);
  const [isSmallDesktop, setIsSmallDesktop] = useState(undefined);
  const [isTablet, setIsTablet] = useState(undefined);
  const [isSmallTablet, setSmallTablet] = useState(undefined);
  const [isMediumTablet, setIsMediumTablet] = useState(undefined);
  const [isMobile, setIsMobile] = useState(undefined);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const updateSize = () => {
        setSize([window.innerWidth, window.innerWidth]);

        if (window.innerWidth > 1280) {
          setIsDesktop(true);
        } else {
          setIsDesktop(false);
        }

        if (window.innerWidth > 1536) {
          setIsLargeDesktop(true);
        } else {
          setIsLargeDesktop(false);
        }

        if (window.innerWidth > 1024 && window.innerWidth <= 1280) {
          setIsSmallDesktop(true);
        } else {
          setIsSmallDesktop(false);
        }

        if (window.innerWidth > 768 && window.innerWidth <= 1024) {
          setIsTablet(true);
        } else {
          setIsTablet(false);
        }

        if (window.innerWidth >= 640 && window.innerWidth <= 768) {
          setIsMediumTablet(true);
        } else {
          setIsMediumTablet(false);
        }

        if (window.innerWidth > 590 && window.innerWidth <= 640) {
          setSmallTablet(true);
        } else {
          setSmallTablet(false);
        }

        if (window.innerWidth < 640) {
          setIsMobile(true);
        } else {
          setIsMobile(false);
        }
      };
      window.addEventListener('resize', updateSize);

      updateSize();

      return () => window.removeEventListener('resize', updateSize);
    }
  }, []);

  return {
    size,
    isDesktop,
    isTablet,
    isSmallTablet,
    isMobile,
    isMediumTablet,
    isSmallDesktop,
    isLargeDesktop,
  };
};

export default useWindowSize;
