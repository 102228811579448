import axios from 'axios';
import Keys from '../../keys';

export const authApi = axios.create({
  baseURL: Keys.API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const authFormDataApi = axios.create({
  baseURL: Keys.API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    Accept:'*/*'
  },
});
export const setApiAuthorizationHeader = (token) => {
  authFormDataApi.defaults.headers.common.Authorization = token
    ? `Bearer ${token}`
    : null;
};