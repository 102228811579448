import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import { Squircle } from "corner-smoothing";
import { useSelector, useDispatch } from "react-redux";
import { selectCountryFrom } from "../../store/selectors/main-selector";
import { useSearchParams } from "react-router-dom";
import "../../index.css";

function SelectCountry({ selected, setSelected, label, list, disabled }) {
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const [searchParams, setSearchParams] = useSearchParams();
  window.onbeforeunload = function () {
    sessionStorage.removeItem("countryFrom");
  };

  useEffect(() => {
    if (selected[Object.keys(selected)[0]]?.name === "Austria") {
      window.sessionStorage.setItem("countryFrom", JSON.stringify("AT"));
    }
  }, [selected]);
  // console.log(selected);
  return (
    <div className="flex flex-col items-start w-full mb-2">
      <span className="text-white ml-4 mb-1 font-semibold	text-xs	">{label}</span>
      <Listbox
        disabled={disabled}
        value={
          selected[Object.keys(selected)[0]]?.name
            ? selected[Object.keys(selected)[0]]?.name
            : selected
        }
        onChange={(value) => {
         
          setSelected(value);
          window.sessionStorage.setItem(
            "countryFrom",
            JSON.stringify(Object.keys(value)[0])
          );
          searchParams.set("from_country", Object.keys(value)[0].toUpperCase());
          setSearchParams(searchParams, { replace: true });
          searchParams.set("currency_to", "UAH");
          setSearchParams(searchParams, { replace: true });
          searchParams.set("currency_from", value[Object.keys(value)]?.from_currency[0]);
          setSearchParams(searchParams, { replace: true });
        }}
        defaultValue={
          selected[Object.keys(selected)[0]]?.name
            ? selected[Object.keys(selected)[0]]?.name
            : selected
        }
      >
        <div className="relative w-full ">
          <Squircle
            cornerRadius={16}
            cornerSmoothing={1}
            className="relative cursor-default h-[74px] px-4 text-left sm:text-sm bg-white text-white w-full"
          >
            <Listbox.Button className="relative cursor-default h-[74px] px-4 text-left sm:text-sm bg-white text-white w-full">
              {Object.keys(selected)?.length > 0 ? (
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row">
                    <img
                      alt={Object.keys(selected)[0]}
                      width={32}
                      height={24}
                      className={"mr-3 rounded-[50%] "}
                      // src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${Object.keys(
                      //   selected
                      // )[0].toUpperCase()}.svg`}
                      src={require(`../../assets/3x2/${Object.keys(
                        selected
                      )[0]?.toUpperCase()}.svg`)}
                    />
                    <span className="block truncate text-base text-[#011A3F] font-bold">
                      {selected[Object.keys(selected)[0]]?.name
                        ? selected[Object.keys(selected)[0]]?.name
                        : ""}
                    </span>
                  </div>
                  <MdKeyboardArrowDown
                    className="h-5 w-5 text-[#011A3F]"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                "Choose an option"
              )}
            </Listbox.Button>
          </Squircle>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100 "
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="z-50 absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {Object.keys(list).map((value, index) => (
                <Listbox.Option
                  key={index}
                  className={() =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      selected[Object.keys(selected)[0]]?.name ===
                      list[value]?.name
                        ? "bg-accent-2 text-accent-1"
                        : "text-gray-900"
                    }`
                  }
                  value={{ [value]: list[value] }}
                >
                  {() => (
                    <div className="flex flex-row">
                      <img
                        alt={value}
                        width={20}
                        height={10}
                        className={"mr-[5px]"}
                        src={require(`../../assets/3x2/${value?.toUpperCase()}.svg`)}
                        // src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${value?.toUpperCase()}.svg`}
                      />
                      <span
                        className={`block truncate ${
                          selected[Object.keys(selected)[0]]?.name ===
                          list[value].name
                            ? "font-medium"
                            : "font-normal"
                        }`}
                      >
                        {list[value].name}
                      </span>
                      {selected[Object.keys(selected)[0]]?.name ===
                      list[value]?.name ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-1">
                          <MdCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default SelectCountry;
