import React, { useState, useEffect } from "react";
import "./home.css";
import { useTranslation } from "react-i18next";

function ForCookie() {
  const [cookie, setCookie] = useState(() => {
    const ls = localStorage.getItem("bookmarks");

    if (ls) return JSON.parse(ls);
    else return true;
  });

  useEffect(() => {
    localStorage.setItem("bookmarks", JSON.stringify(cookie));
  }, [cookie]);

  const { t } = useTranslation();
  return (
    <>
      {cookie ? (
        <div className="forCookie">
          <div className="cookieTitle">
            <p>{t("cookietitle")}</p>
            <div className="cookiebtn">
              <button onClick={(prev) => setCookie(!prev)}>
                {" "}
                {t("cookietwobtn")}
              </button>
              <button onClick={(prev) => setCookie(!prev)}>
                {t("cookieonebtn")}
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default ForCookie;
