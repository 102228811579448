import React, { useEffect, useRef, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import backgroundImage from "../../assets/background.webp";
import Scrill from "../../assets/Skrill.svg";
import Calendar from "../../assets/calendar.png";
import Down from "../../assets/down.png";
import BlCalendar from "../../assets/blackcalendar.png";
import Video from "../../assets/Video.png";
import SmartPay from "../home/SmartPay";
import SelectCountry from "../../components/selectCountry/SelectCountry";
import SelectCurrency from "../../components/selectCurrency/SelectCurrency";
import { Button } from "@material-tailwind/react";
import { IoIosSearch } from "react-icons/io";
import { Helmet } from "react-helmet";


import Mesage from "../../assets/mesage.png";
import Heart from "../../assets/heart.png";
import LinkTag from "../../assets/linkBtn.png";
import LinkTag2 from "../../assets/link2.png";
import Interest from "../../assets/interest.png";
import Android from "../../assets/Badge.png";
import Ios from "../../assets/ios.png";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import CalculateMobile from "../methods/CalculateMobile";
import { Squircle } from "corner-smoothing";
import { Dialog, DialogBody } from "@material-tailwind/react";
import ReactGA from "react-ga4";
import {
  calculate,
  getArticlesById,
  setCurrencyFrom,
  setCurrencyTo,
  setSelectedCountryFrom,
  setSmartPay,
  setValue,
} from "../../store/actions/main-action";
import {
  selectCountries,
  selectCountryFrom,
  selectCurrencyFrom,
  selectCurrencyTo,
  selectIsSender,
  selectLanguage,
  selectLoading,
  selectSmartPay,
  selectValue,
  selectArticlesById,
} from "../../store/selectors/main-selector";

import { useTranslation } from "react-i18next";

import { Spin } from "antd";
import { Link, useSearchParams } from "react-router-dom";

import "./blog.css";

function Blog() {
  ReactGA.send({
    hitType: "pageview",
    page: "https://paytoua.com/blog",
    title: "blog",
  });
  let { id } = useParams();
  let { title } = useParams();

  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const countries = useSelector(selectCountries);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen((cur) => !cur);
  const [nextopen, setNextOpen] = useState(false);
  const value = useSelector(selectValue);
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const selectId = useSelector(selectArticlesById);
  const isSender = useSelector(selectIsSender);
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const [isMore, setIsMore] = useState(true);
  const smartPay = useSelector(selectSmartPay);
  const currencyFrom = useSelector(selectCurrencyFrom);
  const currencyTo = useSelector(selectCurrencyTo);
  const handleNextOpen = () => setNextOpen(!nextopen);

  useEffect(() => {
    setLoading(true);
    try {
      dispatch(
        getArticlesById({
          lange: searchParams.get("lang") || "uk",
          id: id,
        })
      );
    } catch (error) {
      setLoading(false);
    }
  }, [setLoading, dispatch, id]);
 
  useEffect(() => {
    setLoading(selectId ? false : true);
  }, [selectId]);

  const handleClick = () => {
    if (value.length > 0) {
      const payment_method = "all";
      const action = isSender ? "send" : "receive";
      const data = {
        from_country:
          Object.keys(selectedCountryFrom)[0] ||
          searchParams.get("from_country"),
        to_country: "UA",
        from_currency: currencyFrom,
        to_currency: currencyTo,
        get_amount: value,
        payment_method: payment_method,
        action: action,
        to_method: "card",
        smart_type: smartPay,
        lange: language
          ? language === "gb"
            ? "en"
            : language === "ru"
            ? "ru"
            : "uk"
          : "uk",
      };
    
      dispatch(calculate(data));
    }
  };
  function getAllData() {
    if (window.location.pathname.includes(`/blog`)) {
      let x =
        window.location.pathname.split("/").slice(0, 2).join("/") +
        `/${selectId?.title?.replace(/\s+/g, "_")}/${selectId?.id}` +
        `?${searchParams}`;

      window.history.replaceState("", "", `${x}`);
    }
  }

  useEffect(() => {
    getAllData();
  }, [selectId?.title, id]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [id]);

  if (loading) {
    return (
      <div>
        {" "}
        <Spin size={"large"} tip="Loading..." fullscreen />{" "}
      </div>
    );
  }
  
  return (
    <>
      <Helmet>
        <meta property="fb:app_id" content="792549986076930" />

        <meta
          property="og:url"
          content={`https://paytoua.com/blog/${selectId?.title?.replace(
            /\s+/g,
            "_"
          )}/${selectId?.id}?lang=${language}`}
        />
        <meta property="og:type" content="article" />
        <meta property="og:title" content={`${selectId?.title}`} />
      
        <meta property="og:description" content={`${selectId?.description}`} />

        <meta
          property="og:image"
          content={`https://admin.paytoua.com/${selectId?.img_fb}`}
        />

        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:image:type" content="image/jpeg" />
        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />

        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
        <meta property="og:image:alt" content="paytoua.com" />
        <title>{selectId?.title}</title>
        {/* <link
          rel="alternate"
          hreflang="ru"
          href= {`https://paytoua.com/blog/${selectId?.title}/${selectId?.id}?lang=ru`}
        />
        <link
          rel="canonical"
          hreflang="uk"
          href= {`https://paytoua.com/blog/${selectId?.title}/${selectId?.id}?lang=uk`}
        />
        <link
          rel="alternate"
          hreflang="en"
          href= {`https://paytoua.com/blog/${selectId?.title}/${selectId?.id}?lang=gb`}
        /> */}
      </Helmet>

      <div className="relative">
        <div
          className="w-full  blogbackground py-2 bg-cover bg-center min-h-[85vh] md:min-h-[78vh]  lg:min-h-[85vh] 2xl:min-h-[60vh] flex flex-col 2xl:px-24 xl:px-18 lg:px-12 md:px-6 sm:px-8 px-4"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <Header />

          <div className="myContainer">
            <div className="bredcrumbText">
              <h1>
                <Link to={`/?${searchParams}`}> PAYTOUA &gt; </Link>{" "}
                <Link to={`/money_transfers?${searchParams}`}>
                  {" "}
                  {t("money_transfers")} &gt;{" "}
                </Link>{" "}
                <span>{selectId?.title}</span>
              </h1>
            </div>

            <div className="bannerCont mt-5">
              <div className="whitSide ">
                <div className="text-base font-semibold  text-center text-[black]  mt-2 ">
                  <p className=" opacity-[70%]  ">{t("content")}</p>

                  <ul className="text-left opacity-[60%] p-5 ml-2 ">
                   

                    <li
                      dangerouslySetInnerHTML={{ __html: selectId?.content }}
                    ></li>
                  </ul>
                </div>
              </div>

              <div className="internationaltext hideTitle">
                <h2>{selectId?.title}</h2>
              </div>

              <div className="bannerLastBlock hideIcon">
                <div className="dayMonth">
                  <img
                    className="w-[25px] h-[25px] mr-2"
                    src={Calendar}
                    alt="icon"
                  />
                  <p>{selectId?.date}</p>
                </div>
                <div className="socialIcons">
                  <Link
                    target="_blank"
                    to={`http://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffb.paytoua.com%2F%3Ftitle%3Dsharing%26id%3D${id}%26lange%3D${language}`}

                  >
                    <img
                      className="w-[48px] h-[48px] ml-2"
                      src={LinkTag}
                      alt="icon"
                    />
                  </Link>
                  {/* <Link to={`#`}> */}
                  <img
                    className="w-[48px] h-[48px] ml-2"
                    src={Heart}
                    alt="icon"
                  />
                  {/* </Link>
                  <Link to="/"> */}
                  <img
                    className="w-[48px] h-[48px] ml-2"
                    src={Mesage}
                    alt="icon"
                  />
                  {/* </Link> */}
                </div>
              </div>
              {selectId.type === "reviews" ? (
                <div
                  className="imgSid revieSlid"
                  style={{ backgroundColor: `${selectId?.background}` }}
                >
                  <img
                    className="reviewImg"
                    src={`https://admin.paytoua.com/${selectId?.img}`}
                    alt="scrill"
                  />
                </div>
              ) : (
                <div className="imgSid">
                  <img
                    src={`https://admin.paytoua.com/${selectId?.img}`}
                    alt="scrill"
                  />
                </div>
              )}
            </div>
            <div className="bannerLastBlock showIcon">
              <div className="dayMonth">
                <img
                  className="w-[25px] h-[25px] mr-2"
                  src={Calendar}
                  alt="icon"
                />
                <p>{selectId?.date}</p>
              </div>
              <div className="socialIcons">
             
                 
                <Link
                  target="_blank"
                
                  to={`http://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ffb.paytoua.com%2F%3Ftitle%3Dsharing%26id%3D${id}%26lange%3D${language}`}

                     >
                  <img
                    className="w-[48px] h-[48px] ml-2"
                    src={LinkTag}
                    alt="icon"
                  />
                </Link>
                {/* <Link to="#"> */}
                <img
                  className="w-[48px] h-[48px] ml-2"
                  src={Heart}
                  alt="icon"
                />
                {/* </Link> */}
                {/* <Link to="/"> */}
                <img
                  className="w-[48px] h-[48px] ml-2"
                  src={Mesage}
                  alt="icon"
                />
                {/* </Link> */}
              </div>
            </div>
          </div>
        </div>

        <div className="myContainer secondBlock">
          <div className="w-[320px] myCalculate showBlock">
            <div className="compear">
              <p>{selectId?.comparison}</p>
              <img src={Down} alt="paytoua" />
            </div>

            <Squircle
              // cornerRadius={45}
              // cornerSmoothing={1}
              // borderWidth={5}

              className="w-full calculatorSmall  min-w-[100%]  h-[100%] border border-[#FFFFFF12] rounded-[20px] p-[1.5%]"
            >
              <Squircle
                // cornerRadius={45}
                // cornerSmoothing={1}
                className="w-full min-h-[100%] calculate-inner border border-[#FFFFFF30] rounded-[20px]  z-0"
              >
                <Squircle
                  // cornerRadius={45}
                  // cornerSmoothing={1}
                  className="w-full bigCalculater  bg-[#7EB6EA8F] rounded-[30px] relative p-3"
                >
                  <SelectCountry
                    label={t("sender_country")}
                    list={countries}
                    selected={selectedCountryFrom}
                    setSelected={(value) => {
                      dispatch(setSelectedCountryFrom(value));
                      searchParams.set(
                        "from_country",
                        Object.keys(value)[0].toUpperCase()
                      );
                      setSearchParams(searchParams, { replace: true });
                    }}
                  />
                  {/* <div className=" absolute top-[19.5%] right-[40%] w-[36px] h-[36px] rounded-[50%] border border-[#9C9C9C] bg-[#EFF1FBCC] flex justify-center items-center z-10">
                  <Exchange />
                </div> */}
                  <SelectCountry
                    label={t("recipient_country")}
                    list={countries}
                    selected={{ UA: { name: `${t("ukraine")} ` } }}
                    disabled={true}
                  />
                  <div className="flex flex-row justify-between mt-8 z-0 relative space-x-1">
                    <div className="bg-[white]  flex flex-row pl-4 justify-between  relative z-0 rounded-[16px] flex-grow">
                      {/* <ButtonGroupComponent
                      isSender={isSender}
                      setIsSender={(value) => {
                        dispatch(setIsSender(value));
                        searchParams.set("isSender", value);
                        setSearchParams(searchParams, { replace: true });
                      }}
                    /> */}
                      <input
                        type="number"
                        className="w-[55%] outline-none text-[#0E0F0C] text-lg font-semibold"
                        value={value}
                        onChange={(event) => {
                          dispatch(setValue(event.target.value));
                          searchParams.set("value", event.target.value);
                          setSearchParams(searchParams, { replace: true });
                        }}
                      />
                      <div>
                        <SelectCurrency
                          list={
                            isSender
                              ? selectedCountryFrom[
                                  Object.keys(selectedCountryFrom)[0]
                                ]?.from_currency ?? []
                              : selectedCountryFrom[
                                  Object.keys(selectedCountryFrom)[0]
                                ]?.to_currency ?? []
                          }
                          selected={isSender ? currencyFrom : currencyTo}
                          setSelected={(value) => {
                            if (isSender) {
                              dispatch(setCurrencyFrom(value));
                              searchParams.set("currency_from", value);
                              setSearchParams(searchParams, { replace: true });
                            } else {
                              dispatch(setCurrencyTo(value));
                              searchParams.set("currency_to", value);
                              setSearchParams(searchParams, { replace: true });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="relative">
                      <span className="text-[white] ml-4 mb-1 font-semibold	text-xs	 absolute z-10 left-0 top-[-20px]">
                        {!isSender ? t("send") : t("get")}
                      </span>

                      <SelectCurrency
                        list={
                          !isSender
                            ? selectedCountryFrom[
                                Object.keys(selectedCountryFrom)[0]
                              ]?.from_currency ?? []
                            : selectedCountryFrom[
                                Object.keys(selectedCountryFrom)[0]
                              ]?.to_currency ?? []
                        }
                        selected={!isSender ? currencyFrom : currencyTo}
                        setSelected={(value) => {
                          if (!isSender) {
                            dispatch(setCurrencyFrom(value));
                            searchParams.set("currency_from", value);
                            setSearchParams(searchParams, { replace: true });
                          } else {
                            dispatch(setCurrencyTo(value));
                            searchParams.set("currency_to", value);
                            setSearchParams(searchParams, { replace: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <SmartPay
                    smartPay={smartPay}
                    setSmartPay={(value) => {
                      dispatch(setSmartPay(value));
                      value.length > 0
                        ? searchParams.set("smartPay", JSON.stringify(value))
                        : searchParams.delete("smartPay");
                      setSearchParams(searchParams, { replace: true });
                      // window.localStorage.setItem(
                      //   "smartPay",
                      //   JSON.stringify(value)
                      // );
                    }}
                  />
                  <Link to={value.length > 0 && `/methods?${searchParams}`}>
                    <Button
                      onClick={handleClick}
                      className="w-full myButton rounded-[30px] text-lg  bg-[black] text-[white] flex flex-row items-center justify-center py-3 mt-7"
                    >
                      {t("compare")}
                      <IoIosSearch color="white" className="pl-2" size={30} />
                    </Button>
                  </Link>
                  <span className="text-white font-semibold	text-xs	mt-2">
                    {t("it_is_not_necessary_to_fill")}
                  </span>
                </Squircle>
              </Squircle>
            </Squircle>
          </div>
          <div className="combineBlock">
            <div
              className="internationaltext"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(selectId?.text),
              }}
            ></div>
            <div className="w-[320px] myCalculate hideBlock">
              <div className="compear">
                <p>{selectId?.comparison}</p>
                <img src={Down} alt="paytoua" />
              </div>

              <Squircle className="w-full calculatorSmall    h-[100%] border border-[#FFFFFF12] rounded-[20px] p-[1.5%]">
                <Squircle
                  // cornerRadius={45}
                  // cornerSmoothing={1}
                  className="w-full min-h-[100%] calculate-inner border border-[#FFFFFF30] rounded-[20px]  z-0"
                >
                  <Squircle
                    // cornerRadius={45}
                    // cornerSmoothing={1}
                    className="w-full bigCalculater  bg-[#7EB6EA8F] rounded-[30px] relative p-3"
                  >
                    <SelectCountry
                      label={t("sender_country")}
                      list={countries}
                      selected={selectedCountryFrom}
                      setSelected={(value) => {
                        dispatch(setSelectedCountryFrom(value));
                        searchParams.set(
                          "from_country",
                          Object.keys(value)[0].toUpperCase()
                        );
                        setSearchParams(searchParams, { replace: true });
                      }}
                    />
                    {/* <div className=" absolute top-[19.5%] right-[40%] w-[36px] h-[36px] rounded-[50%] border border-[#9C9C9C] bg-[#EFF1FBCC] flex justify-center items-center z-10">
                  <Exchange />
                </div> */}
                    <SelectCountry
                      label={t("recipient_country")}
                      list={countries}
                      selected={{ UA: { name: `${t("ukraine")} ` } }}
                      disabled={true}
                    />
                    <div className="flex flex-row justify-between mt-8 z-0 relative space-x-1">
                      <div className="bg-[white]  flex flex-row pl-4 justify-between  relative z-0 rounded-[16px] flex-grow">
                        {/* <ButtonGroupComponent
                      isSender={isSender}
                      setIsSender={(value) => {
                        dispatch(setIsSender(value));
                        searchParams.set("isSender", value);
                        setSearchParams(searchParams, { replace: true });
                      }}
                    /> */}
                        <input
                          type="number"
                          className="w-[55%] outline-none text-[#0E0F0C] text-lg font-semibold"
                          value={value}
                          onChange={(event) => {
                            dispatch(setValue(event.target.value));
                            searchParams.set("value", event.target.value);
                            setSearchParams(searchParams, { replace: true });
                          }}
                        />
                        <div>
                          <SelectCurrency
                            list={
                              isSender
                                ? selectedCountryFrom[
                                    Object.keys(selectedCountryFrom)[0]
                                  ]?.from_currency ?? []
                                : selectedCountryFrom[
                                    Object.keys(selectedCountryFrom)[0]
                                  ]?.to_currency ?? []
                            }
                            selected={isSender ? currencyFrom : currencyTo}
                            setSelected={(value) => {
                              if (isSender) {
                                dispatch(setCurrencyFrom(value));
                                searchParams.set("currency_from", value);
                                setSearchParams(searchParams, {
                                  replace: true,
                                });
                              } else {
                                dispatch(setCurrencyTo(value));
                                searchParams.set("currency_to", value);
                                setSearchParams(searchParams, {
                                  replace: true,
                                });
                              }
                            }}
                          />
                        </div>
                      </div>
                      <div className="relative">
                        <span className="text-[white] ml-4 mb-1 font-semibold	text-xs	 absolute z-10 left-0 top-[-20px]">
                          {!isSender ? t("send") : t("get")}
                        </span>

                        <SelectCurrency
                          list={
                            !isSender
                              ? selectedCountryFrom[
                                  Object.keys(selectedCountryFrom)[0]
                                ]?.from_currency ?? []
                              : selectedCountryFrom[
                                  Object.keys(selectedCountryFrom)[0]
                                ]?.to_currency ?? []
                          }
                          selected={!isSender ? currencyFrom : currencyTo}
                          setSelected={(value) => {
                            if (!isSender) {
                              dispatch(setCurrencyFrom(value));
                              searchParams.set("currency_from", value);
                              setSearchParams(searchParams, { replace: true });
                            } else {
                              dispatch(setCurrencyTo(value));
                              searchParams.set("currency_to", value);
                              setSearchParams(searchParams, { replace: true });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <SmartPay
                      smartPay={smartPay}
                      setSmartPay={(value) => {
                        dispatch(setSmartPay(value));
                        value.length > 0
                          ? searchParams.set("smartPay", JSON.stringify(value))
                          : searchParams.delete("smartPay");
                        setSearchParams(searchParams, { replace: true });
                        // window.localStorage.setItem(
                        //   "smartPay",
                        //   JSON.stringify(value)
                        // );
                      }}
                    />
                    <Link to={value.length > 0 && `/methods?${searchParams}`}>
                      <Button
                        onClick={handleClick}
                        className="w-full myButton rounded-[30px] text-lg  bg-[black] text-[white] flex flex-row items-center justify-center py-3 mt-7"
                      >
                        {t("compare")}
                        <IoIosSearch color="white" className="pl-2" size={30} />
                      </Button>
                    </Link>
                    <span className="text-white font-semibold	text-xs	mt-2">
                      {t("it_is_not_necessary_to_fill")}
                    </span>
                  </Squircle>
                </Squircle>
              </Squircle>
            </div>
            <div className="interestedIn">
              <h4>{t("also_be_interested")}</h4>
              <div className="interestedBlock">
                {selectId?.more?.map((item) => {
                  return (
                    <Link
                      to={`/blog/${item?.link?.replace(/\s+/g, "_")}/${
                        item?.id
                      }?${searchParams}`}
                      onClick={() => {}}
                    >
                      <div className="interestedBox">
                        <img
                          src={`https://admin.paytoua.com/${item?.img}`}
                          alt="paytoua"
                        />
                        <p className="truncateP">{item?.title}</p>
                        <p className="littleP">{item?.text}</p>
                        <span>
                          <img src={BlCalendar} alt="paytau" />
                          {item?.date}
                        </span>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Blog;
