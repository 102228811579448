import React, { useEffect, useState } from "react";
import backgroundImage from "../../assets/background.webp";
import Header from "../../components/header/Header";
import { useDispatch, useSelector } from "react-redux";
import { getArticles } from "../../store/actions/main-action";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import {
  selectArticles,
  selectLanguage,
} from "../../store/selectors/main-selector";
import { useTranslation } from "react-i18next";
import { Reviews } from "./Reviews";
import { Regulcar } from "./Regular";
import { Popular } from "./Popular";
import { Spin } from "antd";
import { useSearchParams } from "react-router-dom";
import ReactGA from "react-ga4";

function Transfers() {
  ReactGA.send({
    hitType: "pageview",
    page: "https://paytoua.com/money_transfers",
    title: "money_transfers",
  });
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const language = useSelector(selectLanguage);
  const articles = useSelector(selectArticles);
  const linkName = useSelector(selectArticles);

  // function fetchUserEffects() {
  //   const user = dispatch(getArticles("ru"));
  //   console.log(articles);
  //   return user;
  // }
  // useEffect(() => {
  //   fetchUserEffects();
  // }, []);
  useEffect(() => {
    setLoading(true);
    try {
      dispatch(getArticles(searchParams.get("lang") || "uk"));
    } catch (error) {
      setLoading(false);
    }
  }, [setLoading, dispatch]);
  useEffect(() => {
    setLoading(articles?.popular ? false : true);
  }, [articles]);
  if (loading) {
    return <Spin size={"large"} tip="Loading..." fullscreen />;
  }

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paytoua.com/" />
        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />
        <meta property="og:title" content={t("metaTitle")} />
        <meta
          property="og:image"
          content="https://paytoua.com/ff93cf53-f3fb-4232-996c-8b57d3113a12.jpg"
        />
        <meta property="og:description" content={t("metadescrpt")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
        <title> {t("popular_articles_from_the_money_transfers_section")}</title>
        <link
          rel="alternate"
          hreflang="ru"
          href="https://paytoua.com/money_transfers?lang=ru"
        />
        <link
          rel="canonical"
          hreflang="uk"
          href="https://paytoua.com/money_transfers?lang=uk"
        />
        <link
          rel="alternate"
          hreflang="en"
          href="https://paytoua.com/money_transfers?lang=gb"
        />
      </Helmet>

      <div className="relative">
        <div
          className="w-full py-2 bg-cover bg-center min-h-[75vh] md:min-h-[65vh] lg:min-h-[60vh] 2xl:min-h-[60vh] flex flex-col 2xl:px-24 xl:px-18 lg:px-12 md:px-6 sm:px-8 px-4"
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <Header />
        </div>
        <div className="flex containerBig flex-col items-center 2xl:px-24 xl:px-18 lg:px-12 md:px-6 sm:px-8 px-4 w-full">
          {articles?.popular && <Popular popular={articles?.popular} />}
          {articles?.reviews && <Reviews reviews={articles?.reviews} />}
          {articles?.regular && <Regulcar regular={articles?.regular} />}
        </div>
      </div>

      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/NBU_Limits_on_Transfers_from_Abroad./45?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/How_to_Send_Money_to_Ukraine./46?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/How_to_Send_Money_to_PrivatBank./47?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/How_to_Send_Money_to_Monobank_from_Europe?/48?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/SWIFT_Details_for_PrivatBank./49?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Monobank:_SWIFT_and_SEPA_Details./50?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Monobank_or_PrivatBank:_Choosing_a_Bank_for_Receiving_International_Transfers./51?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute h-0 text-transpaent z-[-1]"
        to={`/blog/What_is_"SmartPay"_by_Paytoua.com./52?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/TransferGo_Review:_Fast_and_Cheap_Transfers_Abroad/27?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Wise_(Transferwise)_—_Borderless_Money_Transfers./28?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/ZEN.COM_Review:_How_to_Transfer_Money_Easily_and_Stress-Free./29?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Profee_Review:_International_Transfers_Without_Verification./30?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Paysend_Review:_Features,_Fees,_and_Limits./33?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Western_Union_in_2024:_Understanding_Fees,_Limits,_and_Delivery_Times./34?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Skrill_Review:_How_the_International_Payment_System_Works_in_2024./35?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/10_Ukrainian_Banks_Where_You_Can_Open_an_Account_Online_Without_Visiting./36?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Comparison_of_Fees_for_Money_Transfers_to_Ukraine_in_10_Banks./39?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/How_to_Open_a_Monobank_Account_Online_from_Europe./40?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute  w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Money_transfer_to_Ukraine_using_PayPal./41?lang=gb`}
      >
        -
      </Link>
      <Link
        className="absolute  w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/How_to_find_the_details_for_an_international_transfer_in_Monobank:_Step-by-step_guide./42?lang=gb`}
      >
        -
      </Link>

      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Лимиты_НБУ_на_переводы_из-за_границы./45?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Как_отправить_деньги_в_Украину./46?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Как_отправить_деньги_на_ПриватБанк./47?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Как_отправить_деньги_на_Монобанк_из_Европы?/48?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Monobank:_SWIFT_и_SEPA_реквизиты./50?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute  w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/SWIFT_реквизиты_ПриватБанк./49?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Monobank_или_PrivatBank:_Выбираем_банк_для_приема_международных_переводов./51?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Что_такое_"SmartPay"_от_Paytoua.com./52?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute  w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Обзор_TransferGo:_быстрые_и_дешевые_переводы_за_границу/27?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Wise_(Transferwise)_—_денежные_переводы_без_границ./28?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Обзор_ZEN.COM:_Как_перевести_деньги_легко_и_без_стресса./29?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Обзор_Profee:_международные_переводы_без_верификации./30?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute  w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Обзор_Paysend:_возможности,_комиссии_и_лимиты./33?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0  h-0 text-transpaent z-[-1]"
        to={`/blog/Western_Union_в_2024:_разбираемся_в_тарифах,_ограничениях_и_сроках./34?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Обзор_Skrill:_как_работает_международная_платежная_система_в_2024./35?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/10_Украинских_банков,_где_можно_открыть_счет_онлайн_без_посещения./36?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Сравнение_комиссий_10_банков_за_денежный_перевод_в_Украину./39?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute  w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Как_открыть_счет_в_Monobank_онлайн_из_Европы./40?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute  w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Перевод_денег_в_Украину_с_помощью_PayPal./41?lang=ru`}
      >
        -
      </Link>
      <Link
        className="absolute w-0 h-0 text-transpaent z-[-1]"
        to={`/blog/Как_найти_реквизиты_для_международного_перевода_в_Monobank:_Пошаговое_руководство./42?lang=ru`}
      >
        -
      </Link>
    </>
  );
}

export default Transfers;
