import { takeEvery, put } from "redux-saga/effects";

import { MainTypes } from "../types/main";

import main from "../../services/api/routes/main";
import { toast } from "react-toastify";
import {
  setArticles,
  setCalculateData,
 
  setCountries,
  setArticlesById,
  setMessage,
} from "../actions/main-action";

function* getCountriesSaga({ payload }) {
  try {
    const data = yield main.getCountries(payload);

    yield put(
      setCountries({
        data: data ?? [],
        isHaveInStorage: JSON.parse(
          window.sessionStorage.getItem("countryFrom")
        )
          ? true
          : false,
      })
    );
  } catch (error) {
    toast.error("Ошибка");
  }
}
function* getCalculateSaga({ payload }) {
  try {
    const data = yield main.calculate(payload);
    // console.log('ihhuh', data)
    yield put(setCalculateData(data));
  } catch (error) {
    toast.error("Ошибка");
  }
}
// function* getComments({ payload }) {
//   try {
//     const data = yield main.getComments(payload);

//     yield put(setComments(data));
//   } catch (error) {
//     toast.error("Ошибка");
//   }
// }

function* getArticles({ payload }) {
  try {
    const data = yield main.getArticles(payload);
    yield put(setArticles(data));
  } catch (error) {
    toast.error("Ошибка");
  }
}
function* getArticlesById({ payload }) {
  try {
    const data = yield main.getArticlesById(payload);
    yield put(setArticlesById(data));
    //  console.log(data,"datttttttttta")
  } catch (error) {
    toast.error("Ошибка");
  }
}

function* getMessage({ payload }) {
  try {
    const data = yield main.getMessage(payload);
    yield put(setMessage(data));
    //  console.log(data,"message")
  } catch (error) {
    toast.error("Ошибка");
  }
}
export function* watchMainType() {
  yield takeEvery(MainTypes.GET_COUNTRIES, getCountriesSaga);
  yield takeEvery(MainTypes.GET_ARTICLES, getArticles);
  yield takeEvery(MainTypes.GET_ARTICLES_BY_ID, getArticlesById);
  yield takeEvery(MainTypes.CALCULATE, getCalculateSaga);
  // yield takeEvery(MainTypes.GET_COMMENTS, getComments);
  yield takeEvery(MainTypes.GET_MESSAGE, getMessage);
}
