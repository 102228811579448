import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectLanguage,
  selectCountryFrom,
  selectCountries,
  selectCurrencyFrom,
} from "../../store/selectors/main-selector";

import {
  setSelectedCountryFrom,
  setCurrencyFrom,
} from "../../store/actions/main-action";

function CountryItem({ image, howSent, country, onClickItem }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lang = useSelector(selectLanguage);
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const currencyFrom = useSelector(selectCurrencyFrom);
  const countries = useSelector(selectCountries);
  const handleClick = () => {
    window.sessionStorage.setItem("countryFrom", JSON.stringify(image));
    dispatch(
      setSelectedCountryFrom({
        [image]: countries[sessionStorage.getItem("countryFrom")],
      })
    );
  };

  return (
    <>
      <Link
        to={`/methods?lang=${
          searchParams.get("lang") ? searchParams.get("lang") : lang
        }&from_country=${image}&currency_to=UAH&currency_from=${
          countries[image]?.from_currency[0]
        }&value=1000 `}
      >
        <div
          className={"flex flex-row  mr-20 w-[20%] min-w-[250px] mb-8"}
          onClick={handleClick}
        >
          <img
            alt={image}
            width={32}
            height={24}
            className=" w-9 h-7 rounded-[30px]"
            src={require(`../../assets/3x2/${image}.svg`)}
          />
          <div className=" flex flex-col ml-5 items-start">
            {howSent ? (
              <span className="text-[#00333E] font-bold text-base">
                {howSent}
              </span>
            ) : null}
            {country ? <span>{country}</span> : null}
          </div>
        </div>
      </Link>
      <span className="absolute ">
      <Link
        className="absolute text-white w-0 h-0 z-[-1]"
        to={`/methods?lang=gb&from_country=${image}&currency_to=UAH&currency_from=${countries[image]?.from_currency[0]}&value=1000 `}
      >
        {" "}
        -
      </Link>
      <Link
        className="absolute text-white w-0 h-0 z-[-1]"
        to={`/methods?lang=ru&from_country=${image}&currency_to=UAH&currency_from=${countries[image]?.from_currency[0]}&value=1000 `}
      >
        {" "}
        -
      </Link>
      
      </span>
    </>
  );
}

export default CountryItem;
