import React, { useEffect, useRef, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";
import { RxHamburgerMenu } from "react-icons/rx";
import logo from "../../assets/logo.svg";
import Language from "./Language";
import useWindowSize from "../../hooks/useWindowSize";
import Menu from "./Menu";

import {
  getCountries,
  setCurrencyFrom,
  setCurrencyTo,
  setIsSender,
  setSelectedCountryFrom,
  setSmartPay,
  setValue,
} from "../../store/actions/main-action";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCountries,
  selectLanguage,
} from "../../store/selectors/main-selector";
import { Squircle } from "corner-smoothing";

function Header() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const countries = useSelector(selectCountries);
  const { isMobile, isSmallTablet, isMediumTablet, isTablet, isSmallDesktop } =
    useWindowSize();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const initialized = useRef(false);
  const initialized1 = useRef(false);

  // const language = useSelector(selectLanguage);
  useEffect(() => {
    if (!initialized.current) {
      dispatch(
        getCountries({
          lange: searchParams.get("lang") ?? "uk",
          // language
          //   ? language === "gb"
          //     ? "en"
          //     : language === "ru"
          //     ? "ru"
          //     : "uk"
          //   : "uk",
        })
      );

      initialized.current = true;
    }
  }, [initialized, dispatch]);
  //
  // useEffect(() => {
  //   if (!initialized.current) {
  //     // window.localStorage.removeItem("currencyTo")
  //     dispatch(
  //       getCountries({
  //         lange: language
  //           ? language === "gb"
  //             ? "en"
  //             : language === "ru"
  //             ? "ru"
  //             : "uk"
  //           : "uk",
  //       })
  //     );

  //     initialized.current = true;
  //   }
  // }, [initialized, dispatch,language ]);
  // console.log(countries);
  useEffect(() => {
    if (
      !isMobile &&
      !isSmallTablet &&
      !isMediumTablet &&
      !isTablet &&
      !isSmallDesktop
    ) {
      setIsMenuOpen(false);
    }
  }, [isMobile, isSmallDesktop, isMediumTablet, isTablet, isSmallDesktop]);

  useEffect(() => {
    if (!initialized1.current) {
      // console.log("------");

      if (
        searchParams.get("from_country") &&
        Object.keys(countries).length > 0
      ) {
        dispatch(
          setSelectedCountryFrom({
            [searchParams.get("from_country")]:
              countries[searchParams.get("from_country")],
          })
        );
        !searchParams.get("currency_from") &&
          dispatch(
            setCurrencyFrom(
              countries[searchParams.get("from_country")]?.from_currency[0]
            )
          );
        !searchParams.get("currency_to") &&
          dispatch(
            setCurrencyTo(
              countries[searchParams.get("to_currency")]?.to_currency[0]
            )
          );
      }
      if (searchParams.get("isSender")) {
        dispatch(setIsSender(JSON.parse(searchParams.get("isSender"))));
      }
      if (searchParams.get("currency_from")) {
        // console.log("2222222222222", searchParams.get("currency_from"));
        dispatch(setCurrencyFrom(searchParams.get("currency_from")));
      }
      if (searchParams.get("currency_to")) {
        // console.log("333333333333333",searchParams.get("currency_to"))
        dispatch(setCurrencyTo(searchParams.get("currency_to")));
      }
      if (searchParams.get("value")) {
        dispatch(setValue(searchParams.get("value")));
      }
      if (searchParams.get("smartPay")) {
        dispatch(setSmartPay(JSON.parse(searchParams.get("smartPay"))));
      }
      initialized1.current = true;
    }
  }, [searchParams, countries, initialized1]);
  return (
    <div className="w-full containerBig flex flex-row justify-between items-center">
      <Link to={`/?${searchParams}`} onClick={() => {}}>
        <img src={logo} alt="paytoua" />
      </Link>
      {!isMobile && !isSmallTablet && !isMediumTablet && (
        <div className="flex flex-row items-center pt-3">
          {/* <Link
            to={"/money_transfers"}
            className="mr-8  text-sm text-white"
            onClick={() => {}}
          >
            {t("money_transfers")}
          </Link> */}
          {/* <Link
            // to={"/about"}
            className="mr-16 text-sm text-white"
            onClick={() => {}}
          >
            {t("about_us")}
          </Link> */}
          {/* <Squircle
            cornerRadius={10}
            className="p-[13px] mb-[16px] mt-3 flex items-center w-auto h-12 bg-[#FBFBFB]"
            cornerSmoothing={1}
          >
            <input
              type="number"
              inputMode="decimal"
              onChange={(event) => {
                console.log("event", event.target.valueAsNumber);
              }}
              placeholder="Search"
              className="pl-[11px] text-primary-2 bg-[#FBFBFB] focus:outline-none placeholder:text-grey-1"
              // onChange={handleInputChange}
            />
            <IoIosSearch className="text-[25px] text-grey-1" />
          </Squircle> */}
          <Language />
        </div>
      )}
      {(isMobile || isSmallTablet || isMediumTablet) && (
        <button
          onClick={() => {
            setIsMenuOpen(true);
          }}
        >
          <RxHamburgerMenu className="text-3xl" color="white" />
        </button>
      )}

      <Menu isOpen={isMenuOpen} setIsOpen={setIsMenuOpen} />
    </div>
  );
}

export default Header;
