import React from "react";
import { useTranslation } from "react-i18next";
import { Squircle } from "corner-smoothing";

function CardPayment({
  title,
  image,
  price,
  margin,
  isGood,
  fromCurrency,
  isSelected,
  onClick,
}) {
  const { t } = useTranslation();
  return (
    // <Squircle
    // cornerRadius={30}
    // cornerSmoothing={1}
    // className={`relative overflow-visible bg-[white] flex flex-col items-center justify-center rounded-[30px] cursor-pointer ${isSelected?'border-4 border-[#3580DC] h-[184px] w-[251px] min-w-[202px] sm:h-[196px]':'w-[225px] h-[165px] min-w-[180px] sm:h-[176px]'} ${margin?' mx-4':''}`}
    // >
    <div
      className={`relative overflow-visible bg-[white] flex flex-col items-center justify-center rounded-[40px] cursor-pointer ${
        isSelected
          ? "border-4 border-[#3580DC] h-[184px] w-[251px] min-w-[202px] sm:h-[196px]"
          : "w-[225px] h-[165px] min-w-[180px] sm:h-[176px]"
      } ${margin ? " mx-3" : ""}`}
      onClick={onClick}
      // style={{marginLeft:margin?20:0,marginRight:margin?20:0}}
    >
      {isGood && (
        <div className="flex items-center border-solid border-white border justify-center bg-[#3580DC] rounded-[12px] absolute top-[-11px] px-[8px] z-10 py-[5px]">
          <span className="text-[white] text-xs font-medium">
            {t("best_offer")}
          </span>
        </div>
      )}
      <img src={image} alt={image} className="img" />
      <span className="font-normal text-[black] opacity-[70%] text-sm	">
        {t("translation")}
      </span>
      <span className="text-[black] font-bold text-lg	">{title}</span>
      <span className="text-[black] text-2xl	font-normal">
        {price} {fromCurrency}
      </span>
    </div>
    // </Squircle>
  );
}

export default CardPayment;
