import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import backgroundImage from "../../assets/background.webp";
import Header from "../../components/header/Header";
import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";
import { Helmet } from "react-helmet";
import { Spin } from "antd";
import SelectCountry from "../../components/selectCountry/SelectCountry";
import "../../index.css";
import { getCountries } from "../../store/actions/main-action";
import {
  selectCountries,
  selectCountryFrom,
  selectCurrencyFrom,
  selectCurrencyTo,
  selectIsSender,
  selectLanguage,
  selectLoading,
  selectSmartPay,
  selectValue,
} from "../../store/selectors/main-selector";
import {
  calculate,
  setCurrencyFrom,
  setCurrencyTo,
  setIsSender,
  setLoading,
  setSelectedCountryFrom,
  setSmartPay,
  setValue,
} from "../../store/actions/main-action";
import { ReactComponent as Exchange } from "../../assets/Exchange.svg";
import SelectCurrency from "../../components/selectCurrency/SelectCurrency";
import ButtonGroupComponent from "../../components/buttonGroup/ButtonGroup";
import SmartPay from "./SmartPay";
import { Button } from "@material-tailwind/react";
import { Link, useSearchParams } from "react-router-dom";
import Payments from "./Payments";
import CountryList from "./CountryList";
import Answers from "./Answers";
import SliderComponent from "./sliderComponent/SliderComponent";
import Work from "./Work";
import Imigration from "./Imigration";
import { Squircle } from "corner-smoothing";
import { Dialog, DialogBody } from "@material-tailwind/react";
import ReactGA from "react-ga4";

import "./home.css";
import ForCookie from "./ForCookie";

function HomePage() {
  ReactGA.send({ hitType: "pageview", page: "https://paytoua.com/" });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const countries = useSelector(selectCountries);
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const currencyFrom = useSelector(selectCurrencyFrom);
  const currencyTo = useSelector(selectCurrencyTo);
  const isSender = useSelector(selectIsSender);
  const smartPay = useSelector(selectSmartPay);
  const value = useSelector(selectValue);
  const loading = useSelector(selectLoading);

  const language = useSelector(selectLanguage);
  const [openMod, setOpenMod] = useState(true);
  const handleNextOpen = () => setOpenMod(!openMod);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const handleClick = () => {
    if (value.length > 0) {
      const payment_method = "all";
      const action = isSender ? "send" : "receive";
      const data = {
        from_country: Object.keys(selectedCountryFrom)[0],
        to_country: "UA",
        from_currency: currencyFrom,
        to_currency: currencyTo,
        get_amount: value,
        payment_method: payment_method,
        action: action,
        to_method: "card",
        smart_type: smartPay,
        lange: language
          ? language === "gb"
            ? "en"
            : language === "ru"
            ? "ru"
            : "uk"
          : "uk",
      };
      // dispatch(setLoading(true));
      dispatch(calculate(data));
    }
  };

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paytoua.com/" />

        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />
        <meta property="og:title" content={t("metaTitle")} />
        <meta
          property="og:image"
          content="https://paytoua.com/ff93cf53-f3fb-4232-996c-8b57d3113a12.jpg"
        />
        <meta property="og:description" content={t("metadescrpt")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
        <title> {t("the_bes_way_to_translate")}</title>
        <link
          rel="alternate"
          hreflang="ru"
          href="https://paytoua.com/?lang=ru"
        />
        <link
          rel="canonical"
          hreflang="uk"
          href="https://paytoua.com/?lang=uk"
        />
        <link
          rel="alternate"
          hreflang="en"
          href="https://paytoua.com/?lang=gb"
        />
      </Helmet>
      <div className="w-full ">
        <div
          className="w-full py-2 bg-cover bg-center md:min-h-[130vh] min-h-[120vh]  xl:min-h-[120vh] 2xl-min-h-[85vh] px-4  flex flex-col  2xl:px-40 sm:px-4 xl:px-34 lg:px-28 md:px-22"
          style={{
            backgroundColor: "#2382CB",
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <Header />
          <div className="flex flex-col mt-14 items-center sm:flex-row banner_text containerBig">
            <div className="flex flex-col float-start items-start flex-grow">
              <h1 className="titleText text-white font-bold text-3xl text-left xl:text-6xl">
                {t("find_the_bes_way_to_translate")}
                <span className="titleText text-[#f8d308] font-bold text-3xl xl:text-6xl">
                  {t("to_Ukraine")}
                </span>
              </h1>
              <div className="flex flex-col float-start items-start mt-[32px] w-[100%]  xl:w-[80%] mb-3">
                <span className="text-white font-medium text-left text-base">
                  {t("text1")}
                </span>
                <span className="text-white font-medium text-left text-base">
                  {t("text2")}
                </span>
              </div>
            </div>
            {/* <Squircle
          cornerRadius={45}
          cornerSmoothing={1}
          borderWidth={5}
          className={"bg-[red] min-w-[398px] h-[592px]" }
          ></Squircle> */}
            <Squircle className="md:min-w-[502px] calculate min-w-[100%]  h-[100%] border border-[#FFFFFF12] rounded-[20px] p-[1.5%]">
              <Squircle className="w-full min-h-[100%] calculate-inner border border-[#FFFFFF30] rounded-[20px] p-[5%] z-0">
                <Squircle className="w-full bigCalculater  bg-[#7EB6EA8F] rounded-[40px] relative p-4">
                  <SelectCountry
                    label={t("sender_country")}
                    list={countries}
                    selected={
                      searchParams.get("from_country")
                        ? {
                            [searchParams.get("from_country")]:
                              countries[searchParams.get("from_country")],
                          }
                        : selectedCountryFrom
                    }
                    setSelected={(value) => {
                      // console.log(value, "valueee");
                      dispatch(
                        setSelectedCountryFrom(
                          value ? value : searchParams.get("from_country")
                        )
                      );
                      searchParams.set(
                        "from_country",
                        Object.keys(value)[0].toUpperCase()
                      );
                      setSearchParams(searchParams, { replace: true });
                    }}
                  />
                  {/* <div className=" absolute top-[19.5%] right-[40%] w-[36px] h-[36px] rounded-[50%] border border-[#9C9C9C] bg-[#EFF1FBCC] flex justify-center items-center z-10">
                  <Exchange />
                </div> */}
                  <SelectCountry
                    label={t("recipient_country")}
                    list={countries}
                    selected={{ UA: { name: `${t("ukraine")} ` } }}
                    disabled={true}
                  />
                  <div className="flex flex-row justify-between mt-8 z-0 relative space-x-1">
                    <div className="bg-[white]  flex flex-row pl-4 justify-between  relative z-0 rounded-[20px] flex-grow">
                      {/* <ButtonGroupComponent
                      isSender={isSender}
                      setIsSender={(value) => {
                        dispatch(setIsSender(value));
                        searchParams.set("isSender", value);
                        setSearchParams(searchParams, { replace: true });
                      }}
                    /> */}
                      <input
                        type="number"
                        className="w-[55%] outline-none text-[#0E0F0C] text-lg font-semibold"
                        value={value}
                        onChange={(event) => {
                          dispatch(setValue(event.target.value));
                          searchParams.set("value", event.target.value);
                          setSearchParams(searchParams, { replace: true });
                        }}
                      />
                      <div>
                        <SelectCurrency
                          list={
                            isSender
                              ? selectedCountryFrom[
                                  Object.keys(selectedCountryFrom)[0]
                                ]?.from_currency ?? []
                              : selectedCountryFrom[
                                  Object.keys(selectedCountryFrom)[0]
                                ]?.to_currency ?? []
                          }
                          selected={isSender ? currencyFrom : currencyTo}
                          setSelected={(value) => {
                            if (isSender) {
                              dispatch(setCurrencyFrom(value));
                              searchParams.set("currency_from", value);
                              setSearchParams(searchParams, { replace: true });
                            } else {
                              dispatch(setCurrencyTo(value));
                              searchParams.set("currency_to", value);
                              setSearchParams(searchParams, { replace: true });
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="relative">
                      <span className="text-[white] ml-4 mb-1 font-semibold	text-xs	 absolute z-10 left-0 top-[-20px]">
                        {!isSender ? t("send") : t("get")}
                      </span>

                      <SelectCurrency
                        list={
                          !isSender
                            ? selectedCountryFrom[
                                Object.keys(selectedCountryFrom)[0]
                              ]?.from_currency ?? []
                            : selectedCountryFrom[
                                Object.keys(selectedCountryFrom)[0]
                              ]?.to_currency ?? []
                        }
                        selected={!isSender ? currencyFrom : currencyTo}
                        setSelected={(value) => {
                          if (!isSender) {
                            dispatch(setCurrencyFrom(value));
                            searchParams.set("currency_from", value);
                            setSearchParams(searchParams, { replace: true });
                          } else {
                            dispatch(setCurrencyTo(value));
                            searchParams.set("currency_to", value);
                            setSearchParams(searchParams, { replace: true });
                          }
                        }}
                      />
                    </div>
                  </div>
                  <SmartPay
                    smartPay={smartPay}
                    setSmartPay={(value) => {
                      dispatch(setSmartPay(value));
                      value.length > 0
                        ? searchParams.set("smartPay", JSON.stringify(value))
                        : searchParams.delete("smartPay");
                      setSearchParams(searchParams, { replace: true });
                      // window.localStorage.setItem(
                      //   "smartPay",
                      //   JSON.stringify(value)
                      // );
                    }}
                  />
                  <Link to={value.length > 0 && `/methods?${searchParams}`}>
                    <Button
                      onClick={handleClick}
                      className="w-full rounded-[30px] text-lg  bg-[black] text-[white] flex flex-row items-center justify-center py-3 mt-7"
                    >
                      {t("compare")}
                      <IoIosSearch color="white" className="pl-2" size={30} />
                    </Button>
                  </Link>
                  <span className="text-white font-semibold	text-xs	mt-2">
                    {t("it_is_not_necessary_to_fill")}
                  </span>
                </Squircle>
              </Squircle>
            </Squircle>
          </div>
        </div>

        {/* <Dialog
        size="xl"
        open={openMod}
        handler={handleNextOpen}
        // animate={{
        //   mount: { scale: 1, y: 0 },
        //   unmount: { scale: 0.9, y: -100 },
        // }}
        className="bg-[rgba(0,0,0,.8)] dialogIframe"
      >
        <div className="flex items-center justify-end">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="#eee"
            className="mr-5 mt-5 h-5 w-5"
            onClick={handleNextOpen}
          >
            <path
              fillRule="evenodd"
              d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </div>
        <DialogBody>
          <div className="newHeaderModal">
            <div className="modalText">
              <h3>{t("dialogblogTitle")}</h3>

              <p>{t("dialogblog")}</p>
            </div>
          </div>
        </DialogBody>
      </Dialog> */}
        <div className="2xl:px-35 px-4 xl:px-33 lg:px-28 w-full containerBig relative">
          <Payments />
          <Work />
          {/* <SliderComponent /> */}

          {/* <Imigration /> */}
          <Answers />
          <CountryList />
          <ForCookie />
        </div>
      </div>
    </>
  );
}

export default HomePage;
