import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Button } from "@material-tailwind/react";
import { Modal, Switch } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import { Squircle } from "corner-smoothing";
import { IoMdClose } from "react-icons/io";

function SmartPay({ smartPay, setSmartPay, isMethod, margin }) {
  const [isOpen, setIsOpen] = useState();
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltip1, setShowTooltip1] = useState(false);
  const { t } = useTranslation();
  const { isMobile, isSmallTablet } = useWindowSize();

  const returnPay = (pay) => {
    let text = "";
    if (pay.length === 0) {
      return t("reverse_everything");
    }
    if (pay.includes("PB") && pay.includes("MONO")) {
      return t("monobank");
    }

    if (pay.includes("PB")) {
      text = text + "PrivatBank ";
    }
    if (pay.includes("MONO")) {
      text = text + "monobank";
    }
    if (pay.includes("AB")) {
      text = text + "АБанк, ";
    }
    if (pay.includes("OB")) {
      text = text + "Ощадбанк, ";
    }
    if (pay.includes("USB")) {
      text = text + "УкрСиббанк, ";
    }
    if (pay.includes("PUMB")) {
      text = text + "ПУМБ Банк, ";
    }
    if (pay.includes("Sense")) {
      text = text + "Sense bank, ";
    }
    if (pay.includes("RB")) {
      text = text + "Райфайзен Банк, ";
    }
    return text;
  };
  // console.log(smartPay);
  return (
    <div
      className={`flex flex-row ${
        margin ? "mt-4" : "mt-7"
      } justify-between w-full`}
    >
      <div
        className={`flex items-center ${
          isMethod ? "flex-row justify-between" : "flex-col"
        } relative`}
      >
        <span className="text-white ml-4 font-semibold text-xs mb-2">
          {t("select_bank")}
        </span>
        <Button
          onClick={() => {
            setShowTooltip(true);
          }}
          className={`h-6 rounded-[7px] text-xs px-2 bg-[#0057B8] text-[white] flex flex-row items-center ${
            isMethod ? "mx-2" : ""
          }`}
        >
          SMARTPAY
          <AiOutlineInfoCircle color="white" className="pl-1" size={20} />
        </Button>
      </div>
      {showTooltip && (
        <div className="common-info-style">
          <div className="close-icon" onClick={() => setShowTooltip(false)}>
            <IoMdClose className="opacity-[80%]" size={24} />
          </div>
          <div className="info-text">
            <span className="text-custom">{t("smartpay_paytoua")} </span>
          </div>
          <div className="info-content">
            <span className="text-custom-mod">
              <p> {t("smartpay_paytoua_content")} </p>
            </span>
          </div>
        </div>
      )}
      <Squircle
        cornerRadius={12}
        cornerSmoothing={1.0}
        className={`${
          smartPay.length < 3 ? "px-4" : "px-2"
        } bg-[white] rounded-[20px] flex items-center monoDb justify-center min-h-10 cursor-pointer max-w-[50%]`}
      >
        <div
          className={`flex items-center justify-center min-h-10 cursor-pointer`}
          onClick={() => setIsOpen(true)}
        >
          <span className="text-[#637EA5] text-xs font-medium">
            {returnPay(smartPay)}
          </span>

          {/* <span className="text-[#637EA5] text-xs font-medium">
            {returnPay(smartPay)}
          </span> */}
        </div>
      </Squircle>
      <Modal
        open={isOpen}
        centered
        closeIcon={true}
        footer={false}
        width={isMobile || isSmallTablet ? "80%" : "25%"}
        onCancel={() => setIsOpen(false)}
        className="p-0 custom-modal"
        style={{ maxWidth: "350px", minWidth: "330px" }}
      >
        <div className="w-full relative">
          {showTooltip1 && (
            <Squircle
              cornerRadius={10}
              cornerSmoothing={1.0}
              className="absolute z-10 bottom-10 left-5 common-info-style"
            ></Squircle>
          )}
          <div className="w-full pt-5 px-9 relative">
            <Button
              onClick={() => setShowTooltip1(true)}
              className="h-6 rounded-[7px] text-xs px-2 bg-[#0057B8] text-[white] flex flex-row items-center"
            >
              SMARTPAY
              <AiOutlineInfoCircle color="white" className="pl-1" size={20} />
            </Button>
            <div className="w-full flex flex-row justify-between mt-7">
              <span className="text-xl font-normal text-[#363636]">
                {t("reverse_everything")}
              </span>
              <Switch
                checked={smartPay.length === 2}
                onChange={(value) =>
                  value ? setSmartPay(["PB", "MONO"]) : setSmartPay([])
                }
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="w-full flex flex-row justify-between my-3">
              <span className="text-custom">PrivatBank</span>
              <Switch
                checked={smartPay.includes("PB")}
                onChange={() => {
                  if (!smartPay.includes("PB")) {
                    setSmartPay([...smartPay, "PB"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("PB");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="w-full flex flex-row justify-between">
              <span className="text-custom">monobank</span>
              <Switch
                checked={smartPay.includes("MONO")}
                onChange={(value) => {
                  if (!smartPay.includes("MONO")) {
                    setSmartPay([...smartPay, "MONO"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("MONO");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="text-center mt-5 flex justify-center text-[#808080] text-base font-semibold mb-4">
              {t("dostupni")}
            </div>
          </div>
          <div className="bg-[#D6D6D663] px-9 pt-2 pb-6 rounded-tl-[30px] rounded-tr-[30px] custom-modal-content">
            <div className="w-full flex flex-row justify-between my-3">
              <span className="text-custom1">A-Bank</span>
              <Switch
                disabled={true}
                checked={smartPay.includes("AB")}
                onChange={(value) => {
                  if (!smartPay.includes("AB")) {
                    setSmartPay([...smartPay, "AB"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("AB");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="w-full flex flex-row justify-between">
              <span className="text-custom1">OSCHADBANK</span>
              <Switch
                disabled={true}
                checked={smartPay.includes("OB")}
                onChange={(value) => {
                  if (!smartPay.includes("OB")) {
                    setSmartPay([...smartPay, "OB"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("OB");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="w-full flex flex-row justify-between my-3">
              <span className="text-custom1">UKRSIBBANK</span>
              <Switch
                disabled={true}
                checked={smartPay.includes("USB")}
                onChange={(value) => {
                  if (!smartPay.includes("USB")) {
                    setSmartPay([...smartPay, "USB"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("USB");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="w-full flex flex-row justify-between">
              <span className="text-custom1">PUMB </span>
              <Switch
                disabled={true}
                checked={smartPay.includes("PUMB")}
                onChange={(value) => {
                  if (!smartPay.includes("PUMB")) {
                    setSmartPay([...smartPay, "PUMB"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("PUMB");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="w-full flex flex-row justify-between my-3">
              <span className="text-custom1">Sense Bank</span>
              <Switch
                disabled={true}
                checked={smartPay.includes("Sense")}
                onChange={(value) => {
                  if (!smartPay.includes("Sense")) {
                    setSmartPay([...smartPay, "Sense"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("Sense");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
            <div className="w-full flex flex-row justify-between">
              <span className="text-custom1">Raiffeisen Bank</span>
              <Switch
                disabled={true}
                checked={smartPay.includes("RB")}
                onChange={(value) => {
                  if (!smartPay.includes("RB")) {
                    setSmartPay([...smartPay, "RB"]);
                  } else {
                    let list = [...smartPay];
                    const index = list.indexOf("RB");
                    list.splice(index, 1);
                    setSmartPay(list);
                  }
                }}
                className="bg-[gray] ml-2"
              />
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={showTooltip1}
        centered
        footer={null}
        onCancel={() => setShowTooltip1(false)}
        className="custom-info-modal"
      >
        <div className="common-info-style">
          <div className="close-icon" onClick={() => setShowTooltip1(false)}>
            <IoMdClose className="opacity-[80%]" size={24} />
          </div>
          <div className="info-text">
            <span className="text-custom">{t("smartpay_paytoua")}</span>
          </div>
          <div className="info-content">
            <span className="text-custom-mod">
              <p>{t("smartpay_paytoua_content")}</p>
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default SmartPay;
