import React, { Fragment, useEffect, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import i18n from "../../i18n";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCountryFrom,
  selectLanguage,
} from "../../store/selectors/main-selector";
import { setLanguage } from "../../store/actions/main-action";
import { Squircle } from "corner-smoothing";
import RU from "../../assets/ru.svg";
import "../../index.css";

import { getCountries } from "../../store/actions/main-action";

function Language() {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const optionValues = [
    {
      title: "Ukr",
      short_name: "uk",
    },
    {
      title: "Eng",
      short_name: "gb",
    },

    {
      title: "Ru",
      short_name: "ru",
    },
  ];
  const language = useSelector(selectLanguage);
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const [selected, setSelected] = useState(
    language
      ? optionValues.find((el) => el.short_name === language)
      : optionValues[0]
  );

  useEffect(() => {
    i18n.changeLanguage(searchParams.get("lang") ?? "uk");
    dispatch(setLanguage(searchParams.get("lang") ?? "uk"));

    setSelected(
      searchParams.get("lang")
        ? optionValues.find((el) => el.short_name === searchParams.get("lang"))
        : optionValues[0]
    );
  }, [searchParams, i18n, dispatch, setLanguage]);
  // console.log(selected)
  return (
    <div className="ml-6 langBtn">
      <Listbox
        value={selected}
        onChange={(value) => {
          // console.log(value,"valueeeeeeee")
          setSelected(value);
          i18n.changeLanguage(value.short_name);
          dispatch(setLanguage(value.short_name));
          searchParams.set("lang", value.short_name);
          setSearchParams(searchParams, { replace: true });

          window.sessionStorage.setItem(
            "countryFrom",
            JSON.stringify(searchParams.get("from_country"))
          );

          window.location.reload();
        }}
        defaultValue={selected}
      >
        <div className="relative ">
          <Squircle
            cornerRadius={10}
            className="relative cursor-default h-12 pl-2 langleft text-left sm:text-sm bg-[#2689c6] text-white w-full z-20"
            cornerSmoothing={1}
          >
            <Listbox.Button className="relative cursor-default w-[80px] h-12 pl-2 text-center langleft langright sm:text-sm bg-[#2689c6] text-white  z-20">
              {selected ? (
                <div className="flex flex-row items-center">
                  <img
                    alt={selected?.short_name}
                    className={"mr-[5px] rounded-[50%] object-cover w-4 h-4"}
                    src={
                      selected.short_name === "ru"
                        ? RU
                        : // `http://purecatamphetamine.github.io/country-flag-icons/3x2/${
                          //     selected.short_name.toUpperCase() !== "UK"
                          //       ? selected.short_name.toUpperCase()
                          //       : "UA"
                          //   }.svg`
                          require(`../../assets/3x2/${
                            selected.short_name.toUpperCase() !== "UK"
                              ? selected.short_name.toUpperCase()
                              : "UA"
                          }.svg`)
                    }
                  />
                  <span className="block min-w-[25px]">{selected.title}</span>
                  <span className="pointer-events-none  inset-y-0 right-0 flex items-center langmenu pr-2">
                    <MdKeyboardArrowDown
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </span>
                </div>
              ) : (
                "Choose an option"
              )}
            </Listbox.Button>
          </Squircle>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute bgNew z-30 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white  text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {optionValues.map((value, index) => (
                <Listbox.Option
                  key={index}
                  className={() =>
                    `relative cursor-default w-[80px] select-none py-2 langright pl-10 pr-4 ${
                      selected?.key === value.id
                        ? "bg-accent-2 text-accent-1"
                        : "text-gray-900"
                    }`
                  }
                  value={value}
                >
                  {() => (
                    <>
                      <span
                        className={`block   ${
                          selected?.short_name === value.short_name
                            ? "font-medium"
                            : "font-normal"
                        }`}
                      >
                        {value.title}
                      </span>
                      {selected?.short_name === value.short_name ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-1">
                          <MdCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default Language;
