import React, { useState } from "react";
import ArrowLeft from "../../assets/ArrowLeft.svg";
import ArrowRight from "../../assets/ArrowRight.svg";
import "./slider.css";
import useWindowSize from "../../hooks/useWindowSize";
import { Link } from "react-router-dom";

export default (props) => {
  const [activeSlide, setactiveSlide] = useState(props.activeSlide);
  const { isMobile, isSmallTablet, isMediumTablet, isTablet, isSmallDesktop } =
    useWindowSize();
  const next = () =>
    activeSlide < props.data.length - 1 && setactiveSlide(activeSlide + 1);

  const prev = () => activeSlide > 0 && setactiveSlide(activeSlide - 1);

  const getStyles = (index) => {
    if (activeSlide === index)
      return {
        opacity: 1,
        transform: "translateX(0px) translateZ(0px) rotateY(0deg)",
        zIndex: 10,
      };
    else if (activeSlide - 1 === index)
      return {
        opacity: 1,
        transform:
          isMobile ||
          isSmallTablet ||
          isMediumTablet ||
          isTablet ||
          isSmallDesktop
            ? "translateX(-100px) translateZ(-300px)"
            : "translateX(-300px) translateZ(-400px)",
        zIndex: 9,
      };
    else if (activeSlide + 1 === index)
      return {
        opacity: 1,
        transform:
          isMobile ||
          isSmallTablet ||
          isMediumTablet ||
          isTablet ||
          isSmallDesktop
            ? "translateX(100px) translateZ(-300px)"
            : "translateX(300px) translateZ(-400px)",
        zIndex: 9,
      };
    else
      return {
        opacity: 1,
        transform:
          isMobile ||
          isSmallTablet ||
          isMediumTablet ||
          isTablet ||
          isSmallDesktop
            ? "translateX(-300px) translateZ(-60px)"
            : "translateX(-480px) translateZ(-100px)",
        zIndex: -1,
        display: "none",
      };
  };

  return (
    <>
      {/* carousel */}
      <div className="slideC">
        {props.data.map((item, i) => (
          <React.Fragment key={item.id}>
            <div
              className="slide"
              style={{
                background: item.bgColor,
                ...getStyles(i),
              }}
            >
              {" "}
              <Link className="slideLinks" to={`/${item?.link}`}>
              <SliderContent {...item} />
              </Link>
            </div>
            <div
              className="reflection"
              style={{
                ...getStyles(i),
              }}
            />
          </React.Fragment>
        ))}
      </div>
      {/* carousel */}

      <div className="btns">
        <img src={ArrowLeft} alt="arrow" className="image" onClick={prev} />
        <img src={ArrowRight} alt="arrow" className="image" onClick={next} />
      </div>
    </>
  );
};

const SliderContent = (props) => {
  return (
    <div
      className={
        props.isTransfer
          ? "flex flex-col justify-between h-full  py-4 px-14"
          : "sliderContent"
      }
    >
      {/* <Link to={`/${props.link}`}> */}
        <div className="w-full flex items-center justify-center">
          <img
            src={props.img}
            alt={props.img}
            className={props.isTransfer ? " w-[80%] h-[100px]" : "icon"}
          />
        </div>
        {props.isTransfer ? (
          <div
            className="truncate-multiline "
            dangerouslySetInnerHTML={{ __html: props?.text }}
          ></div>
        ) : (
          <p className="desc">{props?.text}</p>
        )}
      {/* </Link> */}
    </div>
  );
};
