import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IoIosSearch, IoMdClose } from "react-icons/io";
import logo from "../../assets/logo.svg";
import Instagram from "../../assets/instagram.svg";
import Facebook from "../../assets/facebook.svg";
import AppStore from "../../assets/appstore.svg";
import GooglePlay from "../../assets/googleplay.svg";
import Language from "./Language";
import "../../index.css";

function Menu({ isOpen, setIsOpen }) {
  const { t } = useTranslation();

  if (!isOpen) {
    return;
  }
  return (
    <div className="w-full bg-[#1f7cc6]  absolute top-0 left-0 z-50 px-4 py-4">
      <div className="w-full flex flex-row justify-between items-center">
        <img src={logo} alt="paytoua" />
        <button
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <IoMdClose className="text-3xl" color="white" />
        </button>
      </div>
      <div className="flex flex-row menunewclass  px-6 pt-12">
        {/* <div className="rounded-[10px] p-[10px] menuclassdiv flex items-center w-auto h-12 bg-[#EFF5FD]">
          <input
            type="number"
            inputMode="decimal"
            onChange={(event) => {
              console.log("event", event.target.valueAsNumber);
            }}
            placeholder="Search"
            className="pl-[11px] text-primary-2 bg-[#e9f6fc] focus:outline-none placeholder:text-grey-1 "
            // onChange={handleInputChange}
          />
          <IoIosSearch className="text-[25px] text-grey-1" />
        </div> */}
        <Language />
      </div>
      <div className="flex flex-col items-start">
        {/* <Link
         to={"/money_transfers"}
          className="px-6  text-sm text-white mt-[60px]"
          onClick={() => {}}
        >
          {t("money_transfers")}
        </Link> */}
        {/* <Link
          // to={"/about"}
          className="px-6 text-sm text-white mt-[60px]"
          onClick={() => {}}
        >
          {t("about_us")}
        </Link> */}
      </div>
      <div className="flex flex-row mt-[60px] ">
        <Link to={"https://www.instagram.com/paytoua_com/"} target="_blank">
          <img src={Instagram} alt="instagram" className="pr-4" />{" "}
        </Link>
        <Link
          to={"https://www.facebook.com/people/Paytouacom/61560372960077/"}
          target="_blank"
        >
          <img src={Facebook} alt="facebook" />{" "}
        </Link>
      </div>
      {/* <div className="flex flex-row w-full justify-around mb-14 mt-48">
      <img src={AppStore} alt="appstore" />
        <img src={GooglePlay} alt="googleplay" />
      </div> */}
    </div>
  );
}

export default Menu;
