import React from "react";
import Proffe from "../../assets/profee.svg";
import Wise from "../../assets/Wise.svg";
import Transfergo from "../../assets/Transfergo.svg";
import Skrill from "../../assets/Skrill.svg";
import MoneyGram from "../../assets/MoneyGram.svg";
import Revolut from "../../assets/revoult.svg";
import Western from "../../assets/Western.svg";
import Remitly from "../../assets/Remitly.svg";
import Paysend from "../../assets/Paysend.svg";
import KoronaPay from "../../assets/KoronaPay.svg";
import Spoko from "../../assets/Spoko.svg";
import Zen from "../../assets/Zen.svg";
import Payoner from "../../assets/payoner.svg";
import Bunq from "../../assets/Bunq.svg";
 import N26 from "../../assets/N26.svg";
import Starling from "../../assets/Starling.svg";
import Curve from "../../assets/Curve.svg";
import Tommorow from "../../assets/Tommorow.svg";
import Monzo from "../../assets/Monzo.svg";
import Paysera from "../../assets/paysera.svg";
import PrivatBank from "../../assets/PrivatBank.svg";
import './home.css'
import { t } from "i18next";


function Payments() {
  const images = [
    Wise,
    Transfergo,
    Skrill,
    Revolut,
    Proffe,
     Paysend,
     Western,
     MoneyGram,
    Spoko,
    Zen,
    KoronaPay,
    Remitly,
    Payoner,
    Bunq,
     N26,
    Starling,
    Curve,
    Tommorow,
    Monzo,
    Paysera,
    PrivatBank
  ];
  return (
    <div className="flex flex-col justify-center px-5  items-center xl:mt-32 mt-6">
        <span className="text-left font-extrabold home_text text-[#011A3F] text-4xl xl:w-[65%] w-full sm:text-center">{t('intrnational_transactions')}</span>
        <span className="text-left  text-base font-normal text-[#373737] xl:w-[45%] mt-3 w-full sm:text-center">{t('intrnational_transactions_text')}</span>
    <div className='flex flex-row justify-center items-center mt-16 flex-wrap'>
      {images.map((item, index) => {
        return (
          <div key={index} className={"xl:mr-16 mb-8 x mr-4 "} >
            <img src={item} alt={item} className={`${(index===3)&& 'h-6'}`}/>
          </div>
        );
      })}
    </div>
    </div>
  );
}

export default Payments;
