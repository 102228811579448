export const MainTypes = {
  GET_COUNTRIES:"MainTypes/GET_COUNTRIES",
  SET_COUNTRIES:"MainTypes/SET_COUNTRIES",
  SET_SELECTED_COUNTRY_FROM:"MainTypes/SET_SELECTED_COUNTRY_FROM",
  SET_CURRENCY_FROM:"MainTypes/SET_CURRENCY_FROM",
  SET_CURRENCY_TO:"MainTypes/SET_CURRENCY_TO",
  SET_LOADING:"MainTypes/SET_LOADING",
  SET_IS_SENDER:"MainTypes/SET_IS_SENDER",
  SET_SMART_PAY:'MainTypes/SET_SMART_PAY',
  SET_VALUE:'MainTypes/SET_VALUE',
  CALCULATE:'MainTypes/CALCULATE',
  SET_CALCULATE_DATA:'MainTypes/SET_CALCULATE_DATA',
  // GET_COMMENTS:'MainTypes/GET_COMMENTS',
  // SET_COMMENTS:'MainTypes/SET_COMMENTS',
  SET_LANGUAGE:'MainTypes/SET_LANGUAGE',
  GET_ARTICLES:'MainTypes/GET_ARTICLES',
  GET_ARTICLES_BY_ID:'MainTypes/GET_ARTICLES_BY_ID',
  SET_ARTICLES_BY_ID:'MainTypes/SET_ARTICLES_BY_ID',
  SET_ARTICLES:'MainTypes/SET_ARTICLES',
  SET_MESSAGE:"MainTypes/SET_MESSAGE",
  GET_MESSAGE:"MainTypes/GET_MESSAGE"
};
