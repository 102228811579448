import Header from "../../components/header/Header";
import React, { useEffect, useState } from "react";
import "./feedback.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LeftAbout from "../../assets/LeftAbout.svg";
import RightAbout from "../../assets/RightAbout.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import { getMessage } from "../../store/actions/main-action";
import { selectMessage } from "../../store/selectors/main-selector";
function Feedback() {
  ReactGA.send({
    hitType: "pageview",
    page: "https://paytoua.com/feedback",
    title: "feedback",
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const selectMes = useSelector(selectMessage);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paytoua.com/" />
        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />
        <meta property="og:title" content={t("metaTitle")} />
        <meta
          property="og:image"
          content="https://paytoua.com/ff93cf53-f3fb-4232-996c-8b57d3113a12.jpg"
        />
        <meta property="og:description" content={t("metadescrpt")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
        <title>
          {t("any_quations")}
          {t("any_quations2")}
          {t("any_quations3")}
        </title>

        <link
          rel="alternate"
          hreflang="ru"
          href="https://paytoua.com/feedback?lang=ru"
        />
        <link
          rel="canonical"
          hreflang="uk"
          href="https://paytoua.com/feedback?lang=uk"
        />
        <link
          rel="alternate"
          hreflang="en"
          href="https://paytoua.com/feedback?lang=gb"
        />
      </Helmet>

      <div className="w-full min-h-screen  flex flex-col">
        <div className="header-background ">
          <div className="header_container pt-1 pb-1 ">
            <Header />
          </div>
        </div>
        <div className="feedback">
          <img
            src={LeftAbout}
            alt="LeftAbout"
            style={{ position: "absolute", left: 0, top: 100 }}
          />
          <img
            src={RightAbout}
            alt="LeftAbout"
            style={{ position: "absolute", right: 0, top: 100 }}
          />
        </div>

        <div>
          <h1 className="text-3xl heading-contact text-contact text-uppercase">
            {t("any_quations")} <br /> {t("any_quations2")} <br />{" "}
            <span className={"heading-main"}> {t("any_quations3")}</span>
          </h1>
        </div>
        {selectMes[0] === "success" && (
          <div className="sentMessage">
            {" "}
            <span>{t("has_been_sent")}</span>
          </div>
        )}
        <Formik
          initialValues={{ email: "", topic: "", message: "" }}
          validate={(values) => {
            const errors = {};
            if (!values.email) {
              errors.email = "Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
            ) {
              errors.email = "Invalid email address";
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setTimeout(() => {
              dispatch(getMessage({ values }));
              setSubmitting(false);
              resetForm();
            }, 400);
          }}
        >
          {({ isSubmitting }) => (
            <div className="formContainer">
              <Form className="form">
                <label className="label-form">E-mail:</label>
                <Field type="email" name="email" className="input-form" />
                <label className="label-form">{t("subject")}</label>
                <Field type="text" name="topic" className="input-form" />
                <label className="label-form">{t("message")}</label>
                <Field
                  name="message"
                  className="input-form textarea-form"
                  rows={6}
                />
                <button type="submit" className="button-form">
                  {t("to_send")}
                </button>
              </Form>
            </div>
          )}
        </Formik>

        <div className="bootomText">
          <span className="text">
            {t("write_us")}
            <br />
            <Link href="mailto:" target="_top">
              <span className="blueText"> info@paytoua.com</span>
            </Link>{" "}
            {t("write_us2")}
          </span>
        </div>
      </div>
    </>
  );
}

export default Feedback;
