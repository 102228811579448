import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import backgroundImage from "../../assets/background.webp";
import Header from "../../components/header/Header";
import { useTranslation } from "react-i18next";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga4";
import {
  selectCalculateData,
  selectCountryFrom,
  selectCurrencyFrom,
  selectCurrencyTo,
  selectIsSender,
  selectLanguage,
  selectLoading,
  selectSmartPay,
  selectValue,
} from "../../store/selectors/main-selector";
import { calculate, setLoading } from "../../store/actions/main-action";
import { Spin } from "antd";
import PaymentCard from "../../components/paymentCard/PaymentCard";
import { returnColor, returnImage, returnPrefix } from "./utils";
import Calculate from "./Calculate";
import Cards from "./Cards";
import CalculateMobile from "./CalculateMobile";
import useWindowSize from "../../hooks/useWindowSize";
import PaymentCardSmall from "../../components/paymentCard/PaymentCardSmall";
import "../../index.css";
import { useSearchParams } from "react-router-dom";

function Methods() {
  ReactGA.send({
    hitType: "pageview",
    page: "https://paytoua.com/methods",
    title: "methods",
  });
  const { t } = useTranslation();
  const { isMobile, isSmallTablet, isMediumTablet, isTablet, isSmallDesktop } =
    useWindowSize();
  const dispatch = useDispatch();
  const loading = useSelector(selectLoading);
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const currencyFrom = useSelector(selectCurrencyFrom);
  const currencyTo = useSelector(selectCurrencyTo);
  const isSender = useSelector(selectIsSender);
  const smartPay = useSelector(selectSmartPay);
  const value = useSelector(selectValue);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const paymentList = useSelector(selectCalculateData);
  const initialized = useRef(false);
  const [isMore, setIsMore] = useState([]);
  const language = useSelector(selectLanguage);

  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  const handleClick = (index) => {
    if (value.length > 0) {
      const payment_method = "all";
      const action = isSender ? "send" : "receive";
      const to_method = index === 0 ? "card" : index === 1 ? "bank" : "cash";
      const data = {
        from_country:
          Object.keys(selectedCountryFrom)[0] ||
          searchParams.get("from_country"),
        to_country: "UA",
        from_currency: currencyFrom,
        to_currency: currencyTo,
        get_amount: value,
        payment_method: payment_method,
        action: action,
        to_method: to_method,
        smart_type: smartPay,
        lange: language
          ? language === "gb"
            ? "en"
            : language === "ru"
            ? "ru"
            : "uk"
          : "uk",
      };

      setIsMore([]);

      dispatch(setLoading(true));
      dispatch(calculate(data));
    }
  };

  const addIsMore = (value) => {
    const index = isMore.indexOf(value);
    if (index > -1) {
      let list = [...isMore];
      list.splice(index, 1);
      setIsMore(list);
    } else {
      setIsMore([...isMore, value]);
    }
  };
  const checkIsMore = (value) => {
    const index = isMore.indexOf(value);
    return index > -1 ? true : false;
  };

  useEffect(() => {
    if (
      searchParams.get("from_country") === null ||
      searchParams.get("from_country") === ""
    ) {
      window.sessionStorage.setItem("countryFrom", JSON.stringify("AT"));
      searchParams.set("from_country", "AT");
      setSearchParams(searchParams, { replace: true });
      searchParams.set("currency_to", "UAH");
      setSearchParams(searchParams, { replace: true });
      searchParams.set("currency_from", "EUR");
      setSearchParams(searchParams, { replace: true });
    }
  }, [Object.keys(selectedCountryFrom)[0]]);
  useEffect(() => {
    if (!initialized.current) {
      if (
        window.localStorage.getItem("toMethod") &&
        value &&
        currencyFrom &&
        currencyTo
      ) {
        setSelectedIndex(parseInt(window.localStorage.getItem("toMethod")));
        handleClick(parseInt(window.localStorage.getItem("toMethod")));
        initialized.current = true;
      } else if (!window.localStorage.getItem("toMethod")) {
        window.localStorage.setItem("toMethod", 0);
        initialized.current = true;
      }
    }
  }, [
    initialized,
    setSelectedIndex,
    value,
    currencyFrom,
    currencyTo,
    window.sessionStorage.getItem("countryFrom"),
  ]);

  if (loading) {
    return <Spin size={"large"} tip="Loading..." fullscreen />;
  }
  return (
    <>
      <Helmet>
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://paytoua.com/" />
        <meta name="keywords" content={t("metacontent")} />
        <meta property="og:site_name" content="paytoua.com" />
        <meta
          property="og:title"
          content={
            language === "gb"
              ? ` ${t("send_money_from")}
                  ${
                    Object.keys(selectedCountryFrom).length > 0
                      ? selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                          ?.name
                      : ""
                  } ${t("to_Ukraine")}`
              : `
                  ${
                    Object.keys(selectedCountryFrom)?.length > 0
                      ? selectedCountryFrom[
                          Object.keys(selectedCountryFrom)[0]
                        ]?.country_string[0]?.slice(3)
                      : ""
                  }
            
                    ${
                      Object.keys(selectedCountryFrom)?.length > 0
                        ? selectedCountryFrom[
                            Object.keys(selectedCountryFrom)[0]
                          ]?.country_string[1]?.slice(0, -10)
                        : ""
                    } ${t("to_Ukraine")}
                 `
          }
        />
        <meta
          property="og:image"
          content="https://paytoua.com/ff93cf53-f3fb-4232-996c-8b57d3113a12.jpg"
        />
        <meta property="og:description" content={t("metadescrpt")} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta
          name="impact-site-verification"
          value="697309a4-6b6f-445e-b7a5-9d183db623d0"
        />
      
        <title>
          {language === "gb"
            ? ` ${t("send_money_from")}
                  ${
                    Object.keys(selectedCountryFrom).length > 0
                      ? selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                          ?.name
                      : ""
                  } ${t("to_Ukraine")}`
            : `
                  ${
                    Object.keys(selectedCountryFrom)?.length > 0
                      ? selectedCountryFrom[
                          Object.keys(selectedCountryFrom)[0]
                        ]?.country_string[0]?.slice(3)
                      : ""
                  }
            
                    ${
                      Object.keys(selectedCountryFrom)?.length > 0
                        ? selectedCountryFrom[
                            Object.keys(selectedCountryFrom)[0]
                          ]?.country_string[1]?.slice(0, -10)
                        : ""
                    } ${t("to_Ukraine")}
                 `}
        </title>
        <link
          rel="alternate"
          hreflang="ru"
          href="https://paytoua.com/methods?lang=ru"
        />
        <link
          rel="canonical"
          hreflang="uk"
          href="https://paytoua.com/methods?lang=uk"
        />
        <link
          rel="alternate"
          hreflang="en"
          href="https://paytoua.com/methods?lang=gb"
        />
      </Helmet>

      <div className="w-full">
        <div
          className="w-full py-2 bg-cover bg-center min-h-[70vh] md:min-h-[78vh] lg:min-h-[85vh] 2xl:min-h-[60vh] "
          style={{
            backgroundImage: `url(${backgroundImage})`,
          }}
        >
          <div className="flex flex-col  2xl:px-40 px-4 xl:px-36 lg:px-7">
            <Header />
            <span className="xl:text-4xl xl:text-center md:text-3xl	font-bold text-[white] mt-8 mb-5  text-2xl">
              {/* {t("send_money_from")}{" "} */}
              {language === "gb" ? (
                <>
                  {" "}
                  {t("send_money_from")}{" "}
                  {Object.keys(selectedCountryFrom).length > 0
                    ? selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                        ?.name
                    : ""}{" "}
                </>
              ) : (
                <>
                  {Object.keys(selectedCountryFrom)?.length > 0
                    ? selectedCountryFrom[
                        Object.keys(selectedCountryFrom)[0]
                      ]?.country_string[0]?.slice(3)
                    : ""}{" "}
                  <span>
                    {Object.keys(selectedCountryFrom)?.length > 0
                      ? selectedCountryFrom[
                          Object.keys(selectedCountryFrom)[0]
                        ]?.country_string[1]?.slice(0, -10)
                      : ""}{" "}
                  </span>
                </>
              )}
              {/* {Object.keys(paymentList)?.length > 0
                ? paymentList?.from_country_string[0]
                : null} */}
              <span className="text-[#f8d308]">
                {" "}
                {t("to_Ukraine")}
                {/* {Object.keys(paymentList)?.length > 0
                  ? paymentList?.from_country_string[1]
                  : null} */}
              </span>
            </span>
            {!isMobile && !isSmallTablet && !isMediumTablet ? (
              <Calculate
                setSelectedIndex={setSelectedIndex}
                handleClick={handleClick}
              />
            ) : (
              <CalculateMobile
                setSelectedIndex={setSelectedIndex}
                handleClick={handleClick}
              />
            )}
          </div>
          <Cards
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
            handleClick={handleClick}
            setIsMore={setIsMore}
          />
        </div>
        <div className="w-full flex flex-col bg-[#f4f4f4]  items-center justify-center 2xl:px-40 px-4 method_content xl:px-36 lg:px-7">
          <div className="containerBig">
            {/* <div className="flex items-center mt-4 ">
          <span className="text-base font-medium	text-[#171717] xl:mr-6 mr-3 text-left">
            {t('offers_from_money_transfer')}
            <br />
            {t('offers_from_money_transfer_second')}
          </span>
          <AiOutlineInfoCircle color="black" size={30} />
        </div>
        <div className="flex items-center opacity-[60%] my-8">
          <span className="text-sm font-normal	text-[black] mr-6">
            Поточні обмеження Національного банку України
          </span>
          <AiOutlineInfoCircle color="black" size={30} />
        </div>
        <div>
          <span className="text-xs/10 underline font-normal text-[black]">
            Інформація про рекламодавців.
          </span>
        </div> */}
            {paymentList?.data &&
              paymentList?.data?.length > 0 &&
              paymentList?.data.map((item, index) => {
                return !isMobile && !isSmallTablet && !isMediumTablet ? (
                  <PaymentCard
                    information={item.comments}
                    payments={item[item.site]}
                    isMore={checkIsMore(index)}
                    revol={item}
                    bigTitle={
                      item[item.site]?.card
                        ? item[item.site]?.card[0]?.title
                        : ""
                    }
                    bigBankTitle={
                      item[item.site]?.bank
                        ? item[item.site]?.bank[0]?.title
                        : null
                    }
                    get_time={item.get_time}
                    setIsMore={(value) => {
                      addIsMore(index);
                    }}
                    isBig={!item?.more.toString().includes("-")}
                    size={item?.more}
                    nextPaymentName={
                      paymentList?.data.length === 1
                        ? ""
                        : index === 0
                        ? paymentList?.data[1].site
                        : paymentList?.data[0].site
                    }
                    link={item.link}
                    get_amount={item.get_amount}
                    set_amount={item.set_amount}
                    action={paymentList?.action}
                    fromCurrency={paymentList?.from_currency}
                    toCurrency={paymentList?.to_currency}
                    site={item.site}
                    prefix={returnPrefix(item.prefix, t)}
                    commission={item?.commission}
                    percentCommission={item?.percentt + item?.percent_type}
                    message={item?.message}
                    backgroundColor={returnColor(item.site)}
                    image={returnImage(
                      item.site +
                        (item.site === "zen.com" && item.prefix === "PB"
                          ? "privat"
                          : "")
                    )}
                    status={
                      index === 0
                        ? t("recommended")
                        : index === 1
                        ? t("best_offer")
                        : index === 2
                        ? t("the_fastest")
                        : ""
                    }
                  />
                ) : (
                  <PaymentCardSmall
                    information={item.comments}
                    payments={item[item.site]}
                    isMore={checkIsMore(index)}
                    bigTitle={
                      item[item.site]?.card
                        ? item[item.site]?.card[0]?.title
                        : null
                    }
                    bigBankTitle={
                      item[item.site]?.bank
                        ? item[item.site]?.bank[0]?.title
                        : ""
                    }
                    setIsMore={(value) => {
                      addIsMore(index);
                    }}
                    get_time={item?.get_time}
                    isBig={!item?.more.toString().includes("-")}
                    size={item?.more}
                    nextPaymentName={
                      paymentList?.data.length === 1
                        ? ""
                        : index === 0
                        ? paymentList?.data[1].site
                        : paymentList?.data[0].site
                    }
                    link={item.link}
                    get_amount={item.get_amount}
                    set_amount={item.set_amount}
                    action={paymentList?.action}
                    fromCurrency={paymentList?.from_currency}
                    toCurrency={paymentList?.to_currency}
                    site={item.site}
                    prefix={returnPrefix(item.prefix, t)}
                    commission={item?.commission}
                    percentCommission={item?.percentt + item?.percent_type}
                    message={item?.message}
                    backgroundColor={returnColor(item.site)}
                    image={returnImage(
                      item.site +
                        (item.site === "zen.com" && item.prefix === "PB"
                          ? "privat"
                          : "")
                    )}
                    status={
                      index === 0
                        ? t("recommended")
                        : index === 1
                        ? t("best_offer")
                        : index === 2
                        ? t("the_fastest")
                        : ""
                    }
                  />
                );
              })}
            <div className="w-full flex text-left p-[2%]">
              <p className="text-base font-medium">{t("result_text")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Methods;
