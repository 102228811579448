import { MainTypes } from "../types/main";

export const initialState = {
  countries: [],
  selectedCountryFrom: {},
  currencyFrom: "",
  currencyTo: "",
  loading: false,
  isSender: true,
  smartPay: ["MONO"],
  value: "",
  calculateData: [],
  comments: null,
  language: null,
  articles: null,
  getarticleid: null,
  message: {},
};

const mainReducer = (state = initialState, action) => {
  switch (action.type) {
    case MainTypes.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    case MainTypes.SET_COUNTRIES:
      return {
        ...state,
        countries: action.payload.data,

        ...(!action.payload.isHaveInStorage && {
          selectedCountryFrom: { AT: action.payload.data["AT"] },
        }),
        ...(!action.payload.isHaveInStorage && {
          currencyFrom: action.payload.data.AT.from_currency[0],
        }),
        ...(!action.payload.isHaveInStorage && {
          currencyTo: action.payload.data.AT.to_currency[0],
        }),
      };
    case MainTypes.SET_SELECTED_COUNTRY_FROM:
      // console.log("SET_SELECTED_COUNTRY_", currencyTo);
      return {
        ...state,
        selectedCountryFrom: action.payload,
        currencyFrom:
          Object.keys(action.payload).length > 0 &&
          action.payload[Object.keys(action.payload)[0]]?.from_currency
            ?.length > 0
            ? action.payload[Object.keys(action.payload)[0]]?.from_currency[0]
            : "",
        currencyTo:
          Object.keys(action.payload).length > 0 &&
          action.payload[Object.keys(action.payload)[0]]?.to_currency?.length >
            0
            ? action.payload[Object.keys(action.payload)[0]]?.to_currency[0]
            : "",
      };
    case MainTypes.SET_CURRENCY_FROM:
      return {
        ...state,
        currencyFrom: action.payload,
      };
    case MainTypes.SET_CURRENCY_TO:
      return {
        ...state,
        currencyTo: action.payload,
      };
    case MainTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case MainTypes.SET_IS_SENDER:
      return {
        ...state,
        isSender: action.payload,
      };
    case MainTypes.SET_SMART_PAY:
      return {
        ...state,
        smartPay: action.payload,
      };
    case MainTypes.SET_VALUE:
      return {
        ...state,
        value: action.payload,
      };
    case MainTypes.SET_CALCULATE_DATA:
      return {
        ...state,
        calculateData: action.payload,
        loading: false,
      };
    case MainTypes.SET_COMMENTS:
      return {
        ...state,
        comments: action.payload,
      };
    case MainTypes.SET_ARTICLES:
      return {
        ...state,
        articles: action.payload,
      };
    case MainTypes.SET_ARTICLES_BY_ID:
      return {
        ...state,
        getarticleid: action.payload,
      };
    case MainTypes.SET_MESSAGE:
      return {
        ...state,
        message: action.payload,
      };
    default:
      return state;
  }
};
export default mainReducer;
