import { Button } from "@material-tailwind/react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from "react-icons/md";
import Tube from "../../assets/tube.svg";
import Points from "../../assets/points.svg";
import { returnName } from "../../screens/methods/utils";
import { returnWiseName } from "../../screens/methods/utils";
import "./paymentCard.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
function PaymentCard({
  backgroundColor,
  status,
  image,
  commission,
  set_amount,
  revol,
  action,
  toCurrency,
  fromCurrency,
  percentCommission,
  site,
  link,
  get_amount,
  prefix,
  bigTitle,
  bigBankTitle,
  message,
  isBig,
  size,
  nextPaymentName,
  setIsMore,
  isMore,
  payments,
  get_time,
  information,
}) {
  const { t } = useTranslation();
  const checkId = () => {
    let item_id = "";
    Object.keys(payments)?.map((item) => {
      payments[item].map((payment) => {
        item_id = payment.title ? payment.item_id : "";
      });
    });
    return item_id;
  };

  // console.log(Object.values(revol));
  // console.log(
  //   Object.fromEntries(
  //     Object.entries(revol).filter(([key]) => key === "revolut.com")
  //   )
  // );

  const handleMainClick = (e) => {
    if (e.target.tagName !== "BUTTON") {
      setIsMore(!isMore);
    }
  };

  const handleInternalClick = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      // style={{clipPath:' path("M 182 0 c 15.0849 0 22.6274 0 27.3137 4.6863 a 16 16 0 0 1 0 0 c 4.6863 4.6863 4.6863 12.2288 4.6863 27.3137 L 214 42 c 0 15.0849 0 22.6274 -4.6863 27.3137 a 16 16 0 0 1 0 0 c -4.6863 4.6863 -12.2288 4.6863 -27.3137 4.6863 L 32 74 c -15.0849 0 -22.6274 0 -27.3137 -4.6863 a 16 16 0 0 1 0 0 c -4.6863 -4.6863 -4.6863 -12.2288 -4.6863 -27.3137 L 0 32 c 0 -15.0849 0 -22.6274 4.6863 -27.3137 a 16 16 0 0 1 0 0 c 4.6863 -4.6863 12.2288 -4.6863 27.3137 -4.6863 Z")'}}
      className="w-full bg-[white] border-2  border-[#E0E0E0] rounded-[40px] p-4 pr-5 mb-6  shadow-lg hover:shadow-md transition-transform duration-300 transform hover:scale-104 `${isMore ? 'mt-8 mb-14' : '}`"
      onClick={handleMainClick}
    >
      <div className="flex flex-row cursor-pointer">
        <div
          className="h-[27vh] w-[33%] rounded-[40px] relative flex items-center justify-center"
          style={{ backgroundColor: backgroundColor }}
        >
          {/* {status && (
            <div
              className="absolute rounded-xl px-4 top-[-11px] flex justify-center items-center py-1"
              style={{
                backgroundColor:
                  status === t("recommended")
                    ? "#644FE4"
                    : status === t("best_offer")
                    ? "#5FD263"
                    : "#D861DB",
              }}
            >
              <span className="text-[white] text-xs font-medium">{status}</span>
            </div>
          )} */}
          <img src={image} alt="icon-payment" className=" w-[56%] h-16" />
        </div>
        <div className="flex flex-col justify-between items-start w-full ml-4">
          <div className="mt-4 flex smartClass">
            <span className="font-bold text-left leading-4 text-[black] text-2xl opacity-80">
              {site.split(".")[0][0].toUpperCase() +
                site.split(".")[0].substring(1)}{" "}
              {prefix}
              <br />{" "}
              <span className="font-semibold text-sm">
                {bigTitle ? bigTitle : bigBankTitle}
              </span>
              {}
            </span>
            {message && (
              <div
                className="px-2 relative ml-4 rounded-[16px] items-center flex"
                style={{
                  backgroundColor: backgroundColor,
                  opacity: "70%",
                }}
              >
                <span className="text-[black]">{message} </span>
              </div>
            )}
            {prefix === "+ monobank " ? (
              <span className="block text-right smartspan">
                SMARTPAY
                <AiOutlineInfoCircle color="white" className="pl-1" size={20} />
              </span>
            ) : prefix === "+ PrivatBank " ? (
              <span className="block text-right smartspan">
                SMARTPAY
                <AiOutlineInfoCircle color="white" className="pl-1" size={20} />
              </span>
            ) : null}
          </div>
          <div className="flex flex-row  justify-between w-full">
            <div className="w-[60%] flex justify-between">
              <div style={{ textAlign: "left" }}>
                <span className="text-sm text-[black] text-normal opacity-[60%]">
                  {t("commission")}
                </span>
                <br />
                <span className="text-base font-bold opacity-[60%]">
                  {commission} {fromCurrency}
                </span>
                <br />
                <span className="text-sm text-[black] text-normal opacity-[60%]">
                  ({percentCommission})
                </span>
              </div>

              <div style={{ textAlign: "left" }}>
                <span className="text-sm text-[black] text-normal opacity-[60%]">
                  {t("time_of_enrollment")}
                </span>
                <br />
                {/* {site === "revolut.com" ? (

                  <span className="text-base font-semibold text-[black] opacity-[60%]">
                    {t("instantly")}
                  </span>
                ) : (
                  <span className="text-base font-semibold text-[black] opacity-[60%]">
                    {t("on_the_same_day")}
                  </span>
                )} */}
                <span className="text-base font-semibold text-[black] opacity-[60%]">
                  {get_time}
                </span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span className="text-sm text-[black] text-normal opacity-[60%]">
                  {action === "send" ? t("get") : t("send")} (
                  {action === "send" ? toCurrency : fromCurrency})
                </span>
                <br />
                <span className="text-3xl newFont font-bold text-[black] opacity-[70%] md:text-base lg:text-lg">
                  {action === "send" ? get_amount : set_amount}{" "}
                  {action === "send" ? toCurrency : fromCurrency}
                </span>
              </div>
            </div>
            <Button
              onClick={(e) => {
                e.preventDefault();
                window.open(`${link}`, "_blank", "noreferrer");
              }}
              className={
                "bg-[#FFC737] rounded-[14px] myButtonStyle text-base font-semibold  h-12 md:w-[52%] w-[30%] lg:w-[35%]"
              }
            >
              {t("go_to")}{" "}
              {site.split(".")[0][0].toUpperCase() +
                site.split(".")[0].substring(1)}
            </Button>
          </div>
          <span>
            {t("for")}{" "}
            <span style={{ color: isBig ? "#068F14" : "#E30000" }}>
              {isBig ? "▲" : "▼"}
              {size}
              {action === "send" ? toCurrency : fromCurrency}{" "}
              {isBig ? t("more") : t("less")}{" "}
            </span>
            {t("how")} {nextPaymentName}
          </span>
        </div>
      </div>
      {isMore && (
        <div
          className="w-full flex flex-row mt-6 "
          onClick={handleInternalClick}
        >
          <div className=" w-[50%] bg-white rounded-[24px]  pb-3 pr-2.5  flex flex-col ">
            {Object.keys(payments)?.map((item) => {
              return payments[item].map((payment) => {
                // console.log(payment);
                return (
                  <div className="border-[#E6E6E6] border-[2px] p-3 rounded-3xl	mb-4">
                    {payment.title ? (
                      <div className="flex flex-col items-start">
                        <span className="text-[black] text-lg	font-semibold	opacity-[60%] pl-6 pb-4">
                          {payment.title}
                        </span>
                        <div className="flex flex-row w-full items-start">
                          <img src={Points} alt="Points" />
                          <div className="w-full mt-[-3px] ml-2">
                            <div className="flex justify-between mb-3 ">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("you_are_sending")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.set_amount} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("service_commission")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.commission_own} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("exchange_rate")} {item}{" "}
                                {payment.currency_own}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.get_cource_own}{" "}
                                {payment.get_valuta_own}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] w-[50%]">
                                {t("Receive_on_Ukrainian_card")}{" "}
                                {payment.get_valuta_own}
                              </span>
                              <span className="text-xl	 font-bold text-left text-[black] opacity-[70%] ">
                                {payment.get_amount_own}{" "}
                                {payment.get_valuta_own}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 ">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] w-[50%] ">
                                {t("exchange_r")}
                                {t(`${payment.get_valuta_own}/${toCurrency}`)}
                                {t("rate_online")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.cource}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 ">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] w-[50%]">
                                {t("get")}
                                <br />
                                {/* <span className="text-xs">
                                  {!payment?.more.toString().includes("-")
                                    ? "▲"
                                    : "▼"}
                                  {payment?.more}
                                  {action === "send"
                                    ? toCurrency
                                    : fromCurrency}{" "}
                                  {!payment?.more.toString().includes("-")
                                    ? t("more")
                                    : t("less")}{" "}
                                  {t("how")} прямой перевод в гривне
                                </span> */}
                              </span>
                              <span className="text-xl	 font-bold text-left text-[black] opacity-[70%] ">
                                {payment.get_amount} {toCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("time_of_enrollment")}
                              </span>

                              {/* {site === "revolut.com"  ? (
                                <span className="text-base font-semibold text-[black] opacity-[60%]">
                                  {t("instantly")}
                                </span>
                              ) : (
                                <span className="text-base font-semibold text-[black] opacity-[60%]">
                                  {t("on_the_same_day")}
                                </span>
                              )} */}
                              <span className="text-base font-semibold text-[black] opacity-[60%]">
                                {get_time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col items-start">
                        <span className="text-[black] text-lg	font-semibold	opacity-[60%] pl-6 pb-4">
                          {site === "wise.com"
                            ? ` ${returnWiseName(item, t)}`
                            : `${t("translation")} ${returnName(item, t)}`}{" "}
                        </span>
                        <div className="flex flex-row w-full items-start">
                          <img src={Points} alt="Points" />
                          <div className="w-full mt-[-3px] ml-2">
                            <div className="flex justify-between mb-3 ">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("you_are_sending")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.set_amount} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("service_commission")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.commission} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("exchange_rate")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.cource} {toCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 ">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] w-[55%]">
                                {t("get")}
                                <br />
                                {/* <span className="text-xs">
                                  {!payment?.more.toString().includes("-")
                                    ? "▲"
                                    : "▼"}
                                  {payment?.more}
                                  {action === "send"
                                    ? toCurrency
                                    : fromCurrency}{" "}
                                  {!payment?.more.toString().includes("-")
                                    ? t("more")
                                    : t("less")}{" "}
                                  {t("how")} {nextPaymentName}
                                </span> */}
                              </span>
                              <span className="text-xl	noWrapText font-bold text-left text-[black] opacity-[60%] ">
                                {payment.get_amount} {toCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("time_of_enrollment")}
                              </span>
                              {/* {site === "revolut.com"  ? (
                                <span className="text-base font-semibold text-[black] opacity-[60%]">
                                  {t("instantly")}
                                </span>
                              ) : (
                                <span className="text-base font-semibold text-[black] opacity-[60%]">
                                  {t("on_the_same_day")}
                                </span>
                              )} */}
                              <span className="text-base font-semibold text-left text-[black] opacity-[60%] ">
                                {get_time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              });
            })}
          </div>
          <div className="w-[50%] space-y-4">
            {information &&
              Object.keys(information).map((item, index) => {
                if (
                  item === "text" &&
                  (information?.text.id === checkId() || !information?.text.id)
                )
                  return (
                    <div
                      className={
                        "bg-[#D6EFFF] px-4 pt-4 pb-8 mb-2 rounded-3xl	flex flex-col shadow hover:shadow-lg transition-shadow duration-300 transform hover:scale-103"
                      }
                      key={index}
                    >
                      <span className="text-lg font-semibold text-[black]  text-center ">
                        {information?.text.general_title}
                      </span>
                      {information?.text.data.map((elem, index) => {
                        return (
                          <div
                            key={index}
                            style={{ whiteSpace: "pre-line" }}
                            className="text-base font-normal text-left text-[black]  mt-2"
                            dangerouslySetInnerHTML={{ __html: elem?.text }}
                          >
                            {/* {elem?.text} */}
                          </div>
                        );
                      })}
                    </div>
                  );
                else if (
                  item === "link" &&
                  (information?.link.id === checkId() || !information?.link.id)
                )
                  return (
                    <div
                      className={
                        "bg-[#C2E7FF] px-4 pt-4 pb-8 mb-8 rounded-3xl	flex flex-col shadow hover:shadow-lg transition-shadow duration-300 transform hover:scale-103"
                      }
                      key={index}
                    >
                      <span className="text-lg font-semibold text-[black]  text-center">
                        {information?.link.general_title}
                      </span>
                      {information?.link.data.map((elem, index) => {
                        return (
                          <div
                            key={index}
                            style={{ whiteSpace: "pre-line" }}
                            className="text-base font-normal text-left text-[black]  mt-2 ml-3"
                            dangerouslySetInnerHTML={{ __html: elem?.text }}
                          >
                            {/* {elem?.text} */}
                          </div>
                        );
                      })}
                    </div>
                  );
                else if (
                  item === "attention" &&
                  (information?.attention.id === checkId() ||
                    !information?.attention.id)
                )
                  return (
                    <div
                      className={
                        "bg-[#FFF6C9] px-4 pt-4 pb-8 mb-8 rounded-3xl	flex flex-col  border-[1px] border-[#D3D3D3] shadow hover:shadow-lg transition-shadow duration-300 transform hover:scale-103"
                      }
                      key={index}
                    >
                      <span className="text-lg font-semibold text-[black]  text-center">
                        {information?.attention.general_title}
                      </span>
                      {information?.attention.data.map((elem, index) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            key={index}
                            className="text-base font-normal text-left text-[black]  mt-2 "
                            dangerouslySetInnerHTML={{ __html: elem?.text }}
                          >
                            {/* {elem?.text} */}
                          </div>
                        );
                      })}
                    </div>
                  );
                else if (
                  item === "video" &&
                  (information?.attention.id === checkId() ||
                    !information?.attention.id)
                )
                  return (
                    <div
                      className={
                        "bg-[#D6EFFF] px-4 pt-4 pb-8 mb-8 rounded-3xl	shadow hover:shadow-lg transition-shadow duration-300 transform hover:scale-103"
                      }
                      key={index}
                    >
                      <span className="text-lg font-semibold text-[black]  text-left">
                        {information?.video.general_title}
                      </span>
                      <br />
                      {information?.video.data.map((elem, index) => {
                        return (
                          <div className="flex flex-row items-center justify-start">
                            <img src={Tube} alt="Tube" />
                            <div
                              style={{ whiteSpace: "pre-line" }}
                              key={index}
                              className="text-base  font-normal text-left text-[black]  mx-5 my-2"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `http://${elem.src}`,
                                  "_blank",
                                  "noreferrer"
                                );
                              }}
                            >
                              {elem?.text}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
              })}
          </div>
        </div>
      )}

      <div
        className="flex flex-row items-center mt-4 cursor-pointer"
        onClick={() => setIsMore(!isMore)}
      >
        {!isMore ? (
          <MdKeyboardArrowDown
            className="h-5 w-5 text-[#000000] opacity-[60%]"
            aria-hidden="true"
          />
        ) : (
          <MdKeyboardArrowUp
            className="h-5 w-5 text-[#000000] opacity-[60%]"
            aria-hidden="true"
          />
        )}
        <span className="ml-2 font-medium text-sm opacity-[60%] text-[black]">
          {isMore ? `${t("show_less")}` : `${t("more_information")}`}
        </span>
      </div>
    </div>
  );
}

export default PaymentCard;
