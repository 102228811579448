import keys from "../../../keys";
import { authFormDataApi } from "../authInstance";
class Main {
 
  async getCountries(data) {
    try {
      const response = await authFormDataApi.post(
        `${keys.API_URL}countris`,
        data
      );
      return response.data;
    } catch (ex) {
      throw ex.response.data;
    }
  }
  async calculate(data) {
    try {
      const response = await authFormDataApi.post(
        `${keys.API_URL}calculate_result`,
        data
      );
      return response.data;
    } catch (ex) {
      throw ex.response.data;
    }
  }
  // async getComments(data) {
  //   try {
  //     const response = await authFormDataApi.post(
  //       `${keys.API_URL}get_comments`,
  //       data
  //     );
  //     return response.data;
  //   } catch (ex) {
  //     throw ex.response.data;
  //   }
  // }
  async getArticles(data) {
    try {
      const response = await authFormDataApi.post(
        `${keys.API_URL}get_articles?lange=${data}`,
        data
      );
     
      return response.data;
    } catch (ex) {
      throw ex.response.data;
    }
  }
  async getArticlesById(data) {
    try {
      const response = await authFormDataApi.post(
        `${keys.API_URL}get_article_by_id`,
      
        data
      );
    
      return response.data;
    } catch (ex) {
      throw ex.response.data;
    }
  }
  async getMessage(data) {
    try {
      const response = await authFormDataApi.post(
        `${keys.API_URL}send_email`,
      
        data
      );
    
      return response.data;
    } catch (ex) {
      throw ex.response.data;
    }
  }
 }
 

const main = new Main();
export default main;
