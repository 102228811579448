export const selectCountries = (state) => state.mainReducer.countries;
export const selectCountryFrom = (state) => state.mainReducer.selectedCountryFrom;
export const selectCurrencyFrom = (state) => state.mainReducer.currencyFrom;
export const selectCurrencyTo = (state) => state.mainReducer.currencyTo;
export const selectLoading = (state) => state.mainReducer.loading;
export const selectIsSender = (state) => state.mainReducer.isSender;
export const selectSmartPay = (state) => state.mainReducer.smartPay;
export const selectValue = (state) => state.mainReducer.value;
export const selectCalculateData = (state) => state.mainReducer.calculateData;
// export const selectComments = (state) => state.mainReducer.comments;
export const selectLanguage = (state) => state.mainReducer.language;
export const selectArticles = (state) => state.mainReducer.articles;
export const selectArticlesById = (state) => state.mainReducer.getarticleid;

export const selectMessage = (state) => state.mainReducer.message;
