import React from "react";
import Right from '../../assets/Right.svg';
import DownCheveron from '../../assets/DownCheveron.svg';


function Accordion({
  title,
  subTitle,
  isShow,
  onClick,
}) {
  // marginTop: 18,
  // fontFamily: "Roboto",
  // fontSize: 18,
  // fontWeight: 400,
  // color: "#213E4AD9",
  // width:'76%',
  // display:'block'
  return (
    <div className={`cursor-pointer  px-10 py-8 rounded-[30px] bg-[#F7F7F9] flex flex-col mb-0.5 ${isShow&&'bg-[#317FF540] '}`} onClick={onClick}>
      <div className="flex flex-row justify-between">
      <span className=" block text-xl w-[76%] font-semibold text-left">{title}</span>
      <img src={isShow?DownCheveron: Right} alt='Icon'/>
      </div>
      {isShow && <span className=" mt-4 text-lg font-normal text-[#213E4AD9] w-[76%] block text-left">{subTitle}</span>}
    </div>
  );
}

export default Accordion;
