import React from "react";
import { useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import {
  selectCalculateData,
  selectIsSender,
} from "../../store/selectors/main-selector";

import Card from "../../assets/Card.svg";
import Cash from "../../assets/Cash.svg";
import Layer from "../../assets/Layer.svg";
import CardPayment from "../../components/cardPayment/CardPayment";
import useWindowSize from "../../hooks/useWindowSize";
import "../../index.css";

function Cards({ selectedIndex, setSelectedIndex, handleClick, setIsMore }) {
  const { t } = useTranslation();
  const isSender = useSelector(selectIsSender);
  const paymentList = useSelector(selectCalculateData);
  const { isMobile, isSmallTablet, isMediumTablet, isTablet, isSmallDesktop } =
    useWindowSize();
  const dots = () => {
    let array = [];
    if (paymentList?.best_card) {
      array.push(0);
    }
    if (paymentList?.best_bank) {
      array.push(1);
    }
    if (paymentList?.best_cash) {
      array.push(2);
    }
    return array;
  };

  return (
    <div className="mt-14 scrollbar-none scrollmy">
      <span className="text-[white]   text-2xl font-semibold	">
        {t("methods")} {isSender ? t("receiving") : t("shipping")}{" "}
        {t("transfer")}
        {paymentList?.data && paymentList?.data.length > 0 ? (
          <div
            className={
              !isMobile && !isSmallTablet
                ? "flex flex-row items-center justify-center mt-9"
                : " flex flex-row items-center  mx-4 mt-8 mb-10 pt-3 mobilecard  overflow-x-auto scrollbar-none"
            }
          >
            {paymentList?.best_card && (
              <CardPayment
                title={t("on_the_card")}
                price={paymentList?.best_card}
                image={Card}
                isGood={
                  paymentList?.best_card > paymentList?.best_cash &&
                  paymentList?.best_card > paymentList?.best_bank
                    ? true
                    : false
                }
                fromCurrency={paymentList?.to_currency}
                isSelected={selectedIndex === 0}
                onClick={() => {
                  setSelectedIndex(0);
                  handleClick(0);
                  setIsMore([]);
                  // window.localStorage.setItem("toMethod", 0);
                }}
              />
            )}
            {paymentList?.best_bank && (
              <CardPayment
                title={t("to_a_bank_account")}
                price={paymentList?.best_bank}
                image={Layer}
                isGood={
                  paymentList?.best_bank > paymentList?.best_cash &&
                  paymentList?.best_bank > paymentList?.best_card
                    ? true
                    : false
                }
                fromCurrency={paymentList?.to_currency}
                isSelected={selectedIndex === 1}
                onClick={() => {
                  setSelectedIndex(1);
                  handleClick(1);
                  setIsMore([]);
                  window.localStorage.setItem("toMethod", 1);
                }}
                margin={true}
              />
            )}
            {paymentList?.best_cash && (
              <CardPayment
                title={t("receipt_in_cash")}
                price={paymentList?.best_cash}
                image={Cash}
                isGood={
                  paymentList?.best_cash > paymentList?.best_bank &&
                  paymentList?.best_cash > paymentList?.best_card
                    ? true
                    : false
                }
                fromCurrency={paymentList?.to_currency}
                isSelected={selectedIndex === 2}
                onClick={() => {
                  setSelectedIndex(2);
                  handleClick(2);
                  setIsMore([]);
                  window.localStorage.setItem("toMethod", 2);
                }}
              />
            )}
          </div>
        ) : (
          <span className="text-xl text-[white]">{t("no_data")}</span>
        )}
        <div className="flex justify-center items-center mt-4 mb-3">
          {dots()?.map((_, index) => {
            return (
              <div
                key={index}
                style={{
                  width: 8,
                  height: 8,
                  borderRadius: 5,
                  backgroundColor:
                    selectedIndex === index ? "#644FE4" : "white",
                  marginRight: 8,
                  border:
                    selectedIndex === index
                      ? "5px solid #644FE4"
                      : "2px solid transparent",
                }}
              ></div>
            );
          })}
        </div>
      </span>
    </div>
  );
}

export default Cards;
