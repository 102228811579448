import React from "react";
import { useTranslation } from "react-i18next";
import Card1 from "../../assets/Card1.svg";
import Card2 from "../../assets/Card2.png";
import Card3 from "../../assets/Card3.svg";
import backgroundImage from "../../assets/BackWork.svg";
import { Squircle } from "corner-smoothing";
import "./home.css";
function Work() {
  const { t } = useTranslation();

  const works = [
    {
      image: Card1,
      text: t("work1"),
    },
    {
      image: Card2,
      text: t("work2"),
    },
    {
      image: Card3,
      text: t("work3"),
    },
  ];

  return (
    <div className=" mt-36 w-full bg-cover h-[700px] mb-16">
      <span className=" text-[32px] font-semibold sm:text-center text-left block mb-20">
        {t("how_it_works")}
      </span>
      <div className=" h-[470px] flex flex-row justify-between items-center overflow-x-scroll scrollbar-none ">
        {works.map((work, index) => {
          return (
            <Squircle
              className={`forShadow bg-[#F7F7F9]   h-[410px] min-w-[340px] xl:w-[40%]  xl:h-[460px] mr-4 ml-1 text-center border-[2px] border-[#FFFFFF3B] flex flex-col justify-between pb-8 ${
                (index === 0 || index === 1) && "pt-3"
              }`}
              key={index}
            >
              <img
                src={work.image}
                alt="Card"
                className=" w-full bg-cover max-h-[350px]"
              />
              <span className=" text-base font-normal text-[#011A3F] p-1 mx-5">
                {work.text}
              </span>
            </Squircle>
          );
        })}
      </div>
    </div>
  );
}

export default Work;
