import React, { Fragment, useEffect } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { MdCheck, MdKeyboardArrowDown } from "react-icons/md";
import { Squircle } from "corner-smoothing";
// import i18n from "../../i18n";
import { useSearchParams } from "react-router-dom";

function SelectCurrency({ selected, setSelected, list, disabled }) {
  const [searchParams, setSearchParams] = useSearchParams();
  window.onbeforeunload = function () {
    localStorage.removeItem("currency_to");
  };
  // console.log(selected,searchParams.get("currency_to"));
  // useEffect(() => {

  //   searchParams.set("currency_to", "UAH");
  //   setSearchParams(searchParams, { replace: true });
  //   searchParams.set("from_country", "AT");
  //   setSearchParams(searchParams, { replace: true });
  //   searchParams.set("currency_from", "EUR");
  //   setSearchParams(searchParams, { replace: true });
  // }, []);

  return (
    <div>
      <Listbox
        disabled={disabled}
        value={selected}
        onChange={(value) => {
          setSelected(value);
          // window.localStorage.setItem("currency_to", JSON.stringify(value));
        }}
        defaultValue={selected}
      >
        <div className="relative w-full ">
          <Squircle
            cornerRadius={16}
            cornerSmoothing={1}
            className="relative cursor-default rounded-lg h-[74px] px-4 text-left sm:text-sm bg-white text-white w-full"
          >
            <Listbox.Button
              className="relative cursor-default rounded-lg h-[74px]  text-left sm:text-sm bg-white text-white w-full"
              cornerSmoothing={1}
            >
              {selected ? (
                <div className="flex flex-row justify-between">
                  <div className="flex flex-row">
                    <span className="block truncate text-base text-[#011A3F] font-bold">
                      {selected}
                    </span>
                  </div>
                  <MdKeyboardArrowDown
                    className="h-5 w-5 text-[#011A3F]"
                    aria-hidden="true"
                  />
                </div>
              ) : (
                "Choose an option"
              )}
            </Listbox.Button>
          </Squircle>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {list.map((value, index) => (
                <Listbox.Option
                  key={index}
                  className={() =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      selected === value
                        ? "bg-accent-2 text-accent-1"
                        : "text-gray-900"
                    }`
                  }
                  value={value}
                >
                  {() => (
                    <div className="flex flex-row">
                      <span
                        className={`block  ${
                          selected === value ? "font-medium" : "font-normal"
                        }`}
                      >
                        {value}
                      </span>
                      {selected === value ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent-1">
                          <MdCheck className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
}

export default SelectCurrency;
