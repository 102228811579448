import { Button } from "@material-tailwind/react";
import React from "react";
import { useTranslation } from "react-i18next";
import Tube from "../../assets/tube.svg";
import Points from "../../assets/points.svg";
import { returnName } from "../../screens/methods/utils";
import ArrowUp from "../../assets/ArrowUp.svg";
import ArrowDown from "../../assets/ArrowDown.svg";
import { returnWiseName } from "../../screens/methods/utils";
import "./paymentCard.css";
import { AiOutlineInfoCircle } from "react-icons/ai";
function PaymentCardSmall({
  backgroundColor,
  status,
  image,
  commission,
  set_amount,
  action,
  toCurrency,
  fromCurrency,
  percentCommission,
  site,
  get_amount,
  prefix,
  bigTitle,
  bigBankTitle,
  message,
  isBig,
  size,
  nextPaymentName,
  setIsMore,
  isMore,
  payments,
  link,
   get_time,
  information,
}) {
  const { t } = useTranslation();

  return (
    <div className="w-full bg-[white] border-2 border-[#E0E0E0] rounded-[40px] p-4  mb-4 text-left">
      <div
        className="w-full h-32 flex items-center justify-center rounded-[24px] relative"
        style={{ backgroundColor: backgroundColor }}
      >
        {/* {status && (
          <div
            className="absolute rounded-xl px-4 top-[-11px] flex justify-center items-center py-1"
            style={{
              backgroundColor:
                status === "Рекомендовано"
                  ? "#644FE4"
                  : status === "Лучшее предложение"
                  ? "#5FD263"
                  : "#D861DB",
            }}
          >
            <span className="text-[white] text-xs font-medium">{status}</span>
          </div>
        )} */}
        <img src={image} alt="icon-payment" className=" w-[56%] h-14" />
      </div>

      <div className="flex flex-row w-full mt-5 smartClass ">
        <span className="font-bold text-left   leading-4 text-[black] text-2xl opacity-80">
          {site.split(".")[0][0].toUpperCase() +
            site.split(".")[0].substring(1)}{" "}
          {prefix}
          <br />{" "}
          <span className="font-semibold font-normal text-xs">
            {bigTitle ? bigTitle : bigBankTitle}
          </span>
          {}
        </span>
        {prefix === "+ monobank "  ? (
              <span className="block text-right smartspan">
                SMARTPAY
                <AiOutlineInfoCircle color="white" className="pl-1" size={20} />
              </span>
            ):  prefix === "+ PrivatBank " ? ( <span className="block text-right smartspan">
              SMARTPAY
              <AiOutlineInfoCircle color="white" className="pl-1" size={20} />
            </span>) : null}
        {message && (
          <div
            className="px-2 relative ml-4 rounded-[16px] items-center flex"
            style={{
              backgroundColor: backgroundColor,
            }}
          >
            <span className="text-[black]">{message}</span>
          </div>
        )}
      </div>

      {/* <div
        className="flex flex-row w-full mt-5 justify-between"
      >
        <span className="card-sub">Комиссия</span>
        <div className="card-row" style={{ alignItems: "center" }}>
          <span className="card-title">
            {commission} {fromCurrency}
          </span>
          <span className="card-sub" style={{ marginLeft: 12 }}>
            ({percentCommission})
          </span>
        </div>
      </div> */}
      <div className="flex flex-row w-full mt-5 justify-between items-center">
        <span className="text-sm text-[black] text-normal opacity-[60%]">
          {action === "send" ? t("get") : t("send")} (
          {action === "send" ? toCurrency : fromCurrency})
        </span>
        <br />
        <span className="text-3xl noWrapText otherText font-bold text-[black] opacity-[70%]">
          {action === "send" ? get_amount : set_amount}{" "}
          {action === "send" ? toCurrency : fromCurrency}
        </span>
      </div>
      <div className="flex flex-row w-full mt-5 justify-between">
        <span className="text-sm text-[black] text-normal opacity-[60%]">
          {t("time_of_enrollment")}
        </span>{" "}
        {/* {site === "revolut.com" ? (
                  <span className="text-base text-right font-semibold text-[black] opacity-[60%]">
                    {t("instantly")}
                  </span>
                ) : (
                  <span className="text-base text-right font-semibold text-[black] opacity-[60%]">
                    {t("on_the_same_day")}
                  </span>
                )} */}
        <span className="text-base blick font-semibold text-[black] opacity-[60%">
          {get_time}
        </span>
      </div>

      <div className="flex flex-row  justify-between relative items-center mt-6">
        <span className="text-xs font-medium">
          {t('for')}{" "}
          <span
            className="text-xs font-medium"
            style={{ color: isBig ? "#068F14" : "#E30000" }}
          >
            {isBig ? "▲" : "▼"}
            {size}
            {action === "send" ? toCurrency : fromCurrency}{" "}
            {isBig ? t("more") : t("less")}{" "}
          </span>
          {t("how")} {nextPaymentName}
        </span>
        <div className="flex flex-row  items-center">
          <span className="font-small fontmysize ml-1 text-sm opacity-[60%]  text-[black]">
            {isMore ? `${t("show_less")}` : `${t("show_more")}`}
          </span>
          <img
            onClick={() => setIsMore(!isMore)}
            src={isMore ? ArrowUp : ArrowDown}
            alt="Arrow"
            className="ml-2 cursor-pointer"
          />
        </div>
      </div>

      {isMore && (
        <div>
          <div className="w-full flex justify-between flex-col">
            {Object.keys(payments)?.map((item) => {
              return payments[item].map((payment) => {
                return (
                  <div className="border-[#E6E6E6] border-[2px] p-3 rounded-3xl	mt-4">
                    {payment.title ? (
                      <div className="flex flex-col items-start">
                        <span className="text-[black] text-lg	font-semibold	opacity-[60%] pl-6 pb-4">
                          {payment.title}
                        </span>
                        <div className="flex flex-row w-full items-start">
                          <img src={Points} alt="Points" />
                          <div className="w-full mt-[-3px] ml-2">
                            <div className="flex justify-between mb-3 pay_method">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("you_are_sending")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.set_amount} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 pay_method">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("service_commission")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.commission_own} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 pay_method">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("exchange_rate")} {item}{" "}
                                {payment.currency_own}
                              </span>
                              <span className="text-base  font-normal text-right text-[black] opacity-[60%] ">
                                {payment.get_cource_own}{" "}
                                {payment.get_valuta_own}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 pay_method">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("Receive_on_Ukrainian_card")}{" "}
                                {payment.get_valuta_own}
                              </span>
                              <span className="text-xl noWrapText	 font-bold  text-right text-[black] opacity-[60%] ">
                                {payment.get_amount_own}{" "}
                                {payment.get_valuta_own}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 pay_method">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] w-[80%]">
                                {t("exchange_r")}{" "}
                                {t(`${payment.get_valuta_own}/${toCurrency}`)}{" "}
                                {t("rate_online")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.cource}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 pay_method">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] w-[60%]">
                                {t("get")}
                                <br />
                                {/* <span className="text-xs">
                                  {!payment?.more.toString().includes("-")
                                    ? "▲"
                                    : "▼"}
                                  {payment?.more}
                                  {action === "send"
                                    ? toCurrency
                                    : fromCurrency}{" "}
                                  {!payment?.more.toString().includes("-")
                                    ? t("more")
                                    : t("less")}{" "}
                                  {t("how")} прямой перевод в гривне
                                </span> */}
                              </span>
                              <span className="text-xl	noWrapText font-bold text-left text-[black] opacity-[60%] ">
                                {payment.get_amount} {toCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 pay_method">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("time_of_enrollment")}
                              </span>
                              {/* {site === "revolut.com" ? (
                  <span className="text-base font-semibold text-right text-[black] opacity-[60%]">
                    {t("instantly")}
                  </span>
                ) : (
                  <span className="text-base font-semibold text-right  text-[black] opacity-[60%]">
                    {t("on_the_same_day")}
                  </span>
                )} */}
                              <span className="text-base block font-semibold text-right text-[black] opacity-[60%] ">
                                {get_time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="flex flex-col items-start">
                        <span className="text-[black] text-lg	font-semibold	opacity-[60%] pl-6 pb-4">
                          {site === "wise.com"
                            ? ` ${returnWiseName(item, t)}`
                            : `${t("translation")} ${returnName(item, t)}`}{" "}
                        </span>
                        <div className="flex flex-row w-full items-start">
                          <img src={Points} alt="Points" />
                          <div className="w-full mt-[-3px] ml-2">
                            <div className="flex justify-between mb-3 ">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("you_are_sending")}
                              </span>
                              <span className="text-base font-normal text-right text-[black] opacity-[60%] ">
                                {payment.set_amount} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("service_commission")}
                              </span>
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {payment.commission} {fromCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("exchange_rate")}
                              </span>
                              <span className="text-base font-normal text-right text-[black] opacity-[60%] ">
                                {payment.cource} {toCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3 ">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("get")}
                                <br />
                                {/* <span className="text-xs">
                                  {!payment?.more.toString().includes("-")
                                    ? "▲"
                                    : "▼"}
                                  {payment?.more}
                                  {action === "send"
                                    ? toCurrency
                                    : fromCurrency}{" "}
                                  {!payment?.more.toString().includes("-")
                                    ? t("more")
                                    : t("less")}{" "}
                                  {t("how")} {nextPaymentName}
                                </span> */}
                              </span>
                              <span className="text-xl noWrapText	 font-bold text-left text-[black] opacity-[60%] ">
                                {payment.get_amount} {toCurrency}
                              </span>
                            </div>
                            <div className="flex justify-between mb-3">
                              <span className="text-base font-normal text-left text-[black] opacity-[60%] ">
                                {t("time_of_enrollment")}
                              </span>
                              {/* {site === "revolut.com" ? (
                  <span className="text-base font-semibold text-right text-[black] opacity-[60%]">
                    {t("instantly")}
                  </span>
                ) : (
                  <span className="text-base font-semibold text-right text-[black] opacity-[60%]">
                    {t("on_the_same_day")}
                  </span>
                )} */}
                              <span className="text-base block font-semibold  text-right text-[black] opacity-[60%] ">
                                {get_time}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              });
            })}
          </div>
          <div className="mt-4">
            {information &&
              Object.keys(information).map((item, index) => {
                if (item === "text")
                  return (
                    <div
                      className={
                        "bg-[#D6EFFF] px-4 pt-4 pb-8 mb-4 rounded-3xl 	"
                      }
                      key={index}
                    >
                      <span className="text-lg block font-semibold text-[black]  text-center">
                        {information?.text.general_title}
                      </span>
                   
                      {information?.text.data.map((elem, index) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            key={index}
                            dangerouslySetInnerHTML={{ __html: elem?.text }}
                            className="text-base font-normal text-left text-[black]  mt-3"
                          >
                            {/* {elem?.text} */}
                          </div>
                        );
                      })}
                    </div>
                  );
                else if (item === "link")
                  return (
                    <div
                      className={"bg-[#C2E7FF] px-4 pt-4 pb-8 mb-4 rounded-3xl	"}
                      key={index}
                    >
                      <span className="text-lg block font-semibold text-[black]  text-center">
                        {information?.link.general_title}
                      </span>
                 
                      {information?.link.data.map((elem, index) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            key={index}
                            dangerouslySetInnerHTML={{ __html: elem?.text }}
                            className="text-base font-normal text-left text-[black]  mt-3"
                          >
                            {/* {elem?.text} */}
                          </div>
                        );
                      })}
                    </div>
                  );
                else if (item === "attention")
                  return (
                    <div
                      className={
                        "bg-[#FFF6C9] px-4 pt-4 pb-8 mb-4 rounded-3xl border-[1px] border-[#D3D3D3]	"
                      }
                      key={index}
                    >
                      <span className="text-lg block font-semibold text-[black]  text-center">
                        {information?.attention.general_title}
                      </span>
               
                      {information?.attention.data.map((elem, index) => {
                        return (
                          <div
                            style={{ whiteSpace: "pre-line" }}
                            key={index}
                            className="text-base font-normal text-left text-[black]  mt-3"
                            dangerouslySetInnerHTML={{ __html: elem?.text }}
                          >
                            {/* {elem?.text} */}
                          </div>
                        );
                      })}
                    </div>
                  );
                else if (item === "video")
                  return (
                    <div
                      className={"bg-[#D6EFFF] px-4 pt-4 pb-8 mb-4 rounded-3xl	"}
                      key={index}
                    >
                      <span className="text-lg block font-semibold text-[black]  text-center">
                        {information?.video.general_title}
                      </span>
              
                      {information?.video.data.map((elem, index) => {
                        return (
                          <div className="flex flex-row items-center justify-start">
                            <img src={Tube} alt="Tube" />
                            <div
                              style={{ whiteSpace: "pre-line" }}
                              key={index}
                              className="text-base font-normal text-left text-[black] ] mx-5 my-2"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(
                                  `http://${elem.src}`,
                                  "_blank",
                                  "noreferrer"
                                );
                              }}
                            >
                              {elem?.text}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
              })}
          </div>
        </div>
      )}
      <Button
        onClick={(e) => {
          e.preventDefault();
          window.open(`${link}`, "_blank", "noreferrer");
        }}
        className={
          "bg-[#FFC737] myMob_ButtonStyle rounded-[24px] text-base font-semibold  h-12 w-[100%] mt-5"
        }
      >
        Перейти в{" "}
        {site.split(".")[0][0].toUpperCase() + site.split(".")[0].substring(1)}
      </Button>
    </div>
  );
}

export default PaymentCardSmall;
