import {
  Route,
  BrowserRouter,
  Routes,
  useLocation,
  useSearchParams,
} from "react-router-dom";

import HomePage from "./screens/home/HomePage";
import Methods from "./screens/methods/Methods";
import About from "./screens/about/About";
import Transfers from "./screens/transfers/Transfers";
import Footer from "./components/footer/Footer";
import "./App.css";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "./store/selectors/main-selector";
import { getCountries } from "./store/actions/main-action";
import Blog from "./screens/blog/Blog";
import TermsOfService from "./screens/termsOfService/TermsOfService";
import PoliticConfidense from "./screens/PoliticConfidense/PoliticConfidense";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Feedback from "./screens/feedback/Feedback";
import NotFound from "./screens/NotFound/NotFound";
import ReactGA from "react-ga4";

const Layout = ({ children }) => {
  const { t } = useTranslation();

  return (
    <>
      {children}
      <Footer />
    </>
  );
};

function App() {

  
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const language = useSelector(selectLanguage);
  
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path={"/"}
            element={
              <Layout>
                <HomePage />
              </Layout>
            }
          />
          <Route
            path={"/methods"}
            element={
              <Layout>
                <Methods />
              </Layout>
            }
          />

          <Route
            path={"/about_us"}
            element={
              <Layout>
                <About />
              </Layout>
            }
          />
          <Route
            path="*"
            element={
              <Layout>
                <NotFound />
              </Layout>
            }
          />
          <Route
            path={"/feedback"}
            element={
              <Layout>
                <Feedback />
              </Layout>
            }
          />
          <Route
            path={"/blog/:title/:id"}
            element={
              <Layout>
                <Blog />
              </Layout>
            }
          />
          <Route
            path={"/termsofservice"}
            element={
              <Layout>
                <TermsOfService />
              </Layout>
            }
          />
          <Route
            path={"/confidence"}
            element={
              <Layout>
                <PoliticConfidense />
              </Layout>
            }
          />

          <Route
            path={"/money_transfers"}
            element={
              <Layout>
                <Transfers />
              </Layout>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
