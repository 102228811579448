import { MainTypes } from "../types/main";

export const getCountries = (payload) => {
  return {
    type: MainTypes.GET_COUNTRIES,
    payload
  };
};

export const setCountries = (payload) => {
  return {
    type: MainTypes.SET_COUNTRIES,
    payload,
  };
};
export const setComments=(payload)=>{
  return {
    type: MainTypes.SET_COMMENTS,
    payload,
  };
}
export const getComments=(payload)=>{
  return {
    type: MainTypes.GET_COMMENTS,
    payload,
  };
}
export const setSelectedCountryFrom = (payload) => {
  return {
    type: MainTypes.SET_SELECTED_COUNTRY_FROM,
    payload,
  };
};

export const setCurrencyFrom = (payload) => {
  return {
    type: MainTypes.SET_CURRENCY_FROM,
    payload,
  };
};

export const setCurrencyTo = (payload) => {
  return {
    type: MainTypes.SET_CURRENCY_TO,
    payload,
  };
};
export const setLoading = (payload) => {
  return {
    type: MainTypes.SET_LOADING,
    payload,
  };
};
export const setIsSender = (payload) => {
  return {
    type: MainTypes.SET_IS_SENDER,
    payload,
  };
};
export const setSmartPay = (payload) => {
  return {
    type: MainTypes.SET_SMART_PAY,
    payload,
  };
};
export const setValue = (payload) => {
  return {
    type: MainTypes.SET_VALUE,
    payload,
  };
};
export const setCalculateData = (payload) => {
  return {
    type: MainTypes.SET_CALCULATE_DATA,
    payload,
  };
};
export const calculate = (payload) => {
  return {
    type: MainTypes.CALCULATE,
    payload,
  };
};
export const setLanguage = (payload) => {
  return {
    type: MainTypes.SET_LANGUAGE,
    payload,
  };
};
export const getArticles = (payload) => {
  return {
    type: MainTypes.GET_ARTICLES,
    payload,
  };
};
export const setArticles= (payload) => {
  return {
    type: MainTypes.SET_ARTICLES,
    payload,
  };
};
export const getArticlesById = (payload) => {
  return {
    type: MainTypes.GET_ARTICLES_BY_ID,
    payload,
  };
};

export const setArticlesById = (payload) => {
  return {
    type: MainTypes.SET_ARTICLES_BY_ID,
    payload,
  };
};


export const getMessage = (payload) => {
  return {
    type: MainTypes.GET_MESSAGE,
    payload,
  };
};

export const setMessage = (payload) => {
  return {
    type: MainTypes.SET_MESSAGE,
    payload,
  };
};
