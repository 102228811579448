import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useTranslation } from "react-i18next";
import { IoIosSearch } from "react-icons/io";

import SelectCountry from "../../components/selectCountry/SelectCountry";
import {
  selectCountries,
  selectCountryFrom,
  selectCurrencyFrom,
  selectCurrencyTo,
  selectIsSender,
  selectSmartPay,
  selectValue,
} from "../../store/selectors/main-selector";
import {
  setCurrencyFrom,
  setCurrencyTo,
  setIsSender,
  setSelectedCountryFrom,
  setSmartPay,
  setValue,
} from "../../store/actions/main-action";
import { ReactComponent as Exchange } from "../../assets/Exchange.svg";
import SelectCurrency from "../../components/selectCurrency/SelectCurrency";
import ButtonGroupComponent from "../../components/buttonGroup/ButtonGroup";
import SmartPay from "../home/SmartPay";
import { useSearchParams } from "react-router-dom";
import { Squircle } from "corner-smoothing";

function Calculate({ setSelectedIndex, handleClick }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const countries = useSelector(selectCountries);
  const selectedCountryFrom = useSelector(selectCountryFrom);
  const currencyFrom = useSelector(selectCurrencyFrom);
  const currencyTo = useSelector(selectCurrencyTo);
  const isSender = useSelector(selectIsSender);
  const smartPay = useSelector(selectSmartPay);
  const value = useSelector(selectValue);

  return (
    <div className="w-full  border-[2px] border-[white] containerBig rounded-[70px] py-8  mt-14 relative flex flex-col items-center shadow-lg ">
      <div className="flex flex-row  items-center   space-x-2.5 justify-center w-full">
        <div className="w-[24%] ">
          <SelectCountry
            label={t("sender_country")}
            list={countries}
            selected={
              searchParams.get("from_country")
                ? {
                    [searchParams.get("from_country")]:
                      countries[searchParams.get("from_country")],
                  }
                : selectedCountryFrom
            }
            defaultValue={searchParams.get("from_country")}
            setSelected={(value) => {
              dispatch(
                setSelectedCountryFrom(
                  value ? value : searchParams.get("from_country")
                )
              );
              searchParams.set(
                "from_country",
                Object.keys(value)[0].toUpperCase()
              );
              setSearchParams(searchParams, { replace: true });

                       }}
          />
        </div>
        {/* <div className=" absolute left-[25.5%] md:left-[22.5%] lg:left-[25.5%] w-[36px] h-[36px] rounded-[50%] border border-[#9C9C9C] bg-[#EFF1FBCC] flex justify-center items-center z-10">
          <Exchange />
        </div> */}
        <div className="w-[24%] chooseCantry ">
          <SelectCountry
            label={t("recipient_country")}
            list={countries}
            selected={{ UA: { name: `${t("ukraine")} ` } }}
            disabled={true}
          />
        </div>
        <div className="bg-[white] w-[24%]  flex flex-row pl-4 justify-between rounded-[20px] relative mt-2 ">
          {/* <ButtonGroupComponent
            isSender={isSender}
            setIsSender={(value) => {
              dispatch(setIsSender(value));
              searchParams.set("isSender", value);
              setSearchParams(searchParams, { replace: true });
              // window.localStorage.setItem("isSender", value);
            }}
          /> */}
          <input
            type="number"
            className="w-[55%] outline-none ml-3 text-[#0E0F0C] text-lg font-semibold"
            value={value}
            onChange={(event) => {
              dispatch(setValue(event.target.value));
              searchParams.set("value", event.target.value);
              setSearchParams(searchParams, { replace: true });
              // window.localStorage.setItem("value", value);
            }}
          />
          <div>
            <SelectCurrency
              list={
                isSender
                  ? selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                      ?.from_currency ?? []
                  : selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                      ?.to_currency ?? []
              }
              selected={isSender ? currencyFrom : currencyTo}
              setSelected={(value) => {
                if (isSender) {
                  dispatch(setCurrencyFrom(value));
                  searchParams.set("currency_from", value);
                  setSearchParams(searchParams, { replace: true });
                } else {
                  dispatch(setCurrencyTo(value));
                  searchParams.set("currency_to", value);
                  setSearchParams(searchParams, { replace: true });
                }
              }}
            />
          </div>
        </div>
        <div className="relative mt-2  w-[12%]">
          <span className="text-[white] ml-4 mb-1 font-semibold	text-xs	 absolute z-10 left-0 top-[-20px]">
            {!isSender ? t("send") : t("get")}
          </span>

          <SelectCurrency
            list={
              !isSender
                ? selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                    ?.from_currency ?? []
                : selectedCountryFrom[Object.keys(selectedCountryFrom)[0]]
                    ?.to_currency ?? []
            }
            selected={!isSender ? currencyFrom : currencyTo}
            setSelected={(value) => {
              if (!isSender) {
                dispatch(setCurrencyFrom(value));
                searchParams.set("currency_from", value);
                setSearchParams(searchParams, { replace: true });
              } else {
                dispatch(setCurrencyTo(value));
                searchParams.set("currency_to", value);
                setSearchParams(searchParams, { replace: true });
              }
            }}
          />
        </div>
        <Squircle
          cornerRadius={16}
          cornerSmoothing={1}
          onClick={() => {
            window.localStorage.setItem("toMethod", 0);
            setSelectedIndex(0);
            handleClick(0);
          }}
          className="w-[74px] cursor-pointer bg-[#FFC737] flex items-center justify-center rounded-md h-[64px] mt-2"
        >
          <IoIosSearch color="black" size={40} />
        </Squircle>
      </div>
      <div className="flex flex-row items-center">
        <SmartPay
          margin={true}
          isMethod={true}
          smartPay={smartPay}
          setSmartPay={(value) => {
            dispatch(setSmartPay(value));
            value.length > 0
              ? searchParams.set("smartPay", JSON.stringify(value))
              : searchParams.delete("smartPay");
            setSearchParams(searchParams, { replace: true });
            // window.localStorage.setItem("smartPay", JSON.stringify(value));
          }}
        />
      </div>
    </div>
  );
}

export default Calculate;
