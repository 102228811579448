import React, { useEffect } from "react";

import { Link, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectCountries } from "../../store/selectors/main-selector";
import CountryItem from "../../components/countryItem/CountryItem";
import { useDispatch, useSelector } from "react-redux";
import { selectLanguage } from "../../store/selectors/main-selector";
import {
  selectCountryFrom,
  selectCurrencyFrom,
  selectCurrencyTo,
  selectIsSender,
  selectSmartPay,
  selectValue,
} from "../../store/selectors/main-selector";

import {
  calculate,
  setLoading,
  setSelectedCountryFrom,
} from "../../store/actions/main-action";

function CountryList() {
  const { t } = useTranslation();

  const list = useSelector(selectCountries);

  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();
  const countries = useSelector(selectCountries);
  const selectedCountryFrom = useSelector(selectCountryFrom);

  return (
    <div className="w-full mt-24">
      <span className="sm:text-center font-extrabold home_text    text-[#011A3F] text-4xl xl:w-[65%] w-full text-left">
        {t("find_the_bes_way_to_translate")}
        <br />
        {t("money_to_Ukraine")}
      </span>
      <div className="flex overflow-x-scroll scrollbar-none ">
        <div className="grid  grid-cols-4   mt-16   min-w-[1000px]">
          {Object.keys(list).map((item, index) => {
           
            return (
              <CountryItem
                image={item}
                howSent={list[item]?.country_string[0]}
                country={list[item]?.country_string[1]}
                onClickItem={list}
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CountryList;
