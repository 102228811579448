import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Squircle } from "corner-smoothing";
import "./styles.css";
import useWindowSize from "../../hooks/useWindowSize";
import { Link, useSearchParams } from "react-router-dom";

export const Popular = ({ popular }) => {
  const { t } = useTranslation();
  const { isMobile, isSmallTablet, isTablet, isMediumTablet, isSmallDesktop } =
    useWindowSize();

  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div className="w-full  flex flex-col items-center md:mt-[-48vh] mt-[-60vh] mb-20 ">
      <div className=" flex flex-col items-center">
        <p className="poptext font-semibold sm:text-[32px] text-base  mb-10 text-white sm:w-[600px] w-[300px]">
          {t("popular_articles_from_the_money_transfers_section")}
        </p>
       
      </div>
      {isMobile ||
      isSmallTablet ||
      isTablet ||
      isMediumTablet ||
      isSmallDesktop ? (
        <div className="w-full my_order">
          <Link
            className="w-full "
            to={`/blog/${popular[0]?.link?.replace(/\s+/g, "_")}/${
              popular[0]?.id
            }?${searchParams}`}
          >
            <Squircle className=" h-[500px] newClass  p-5 pb-7 bg-[#F7F7F9] flex flex-col  shadow-lg myFirstDIV">
              <Squircle
                cornerRadius={42}
                cornerSmoothing={1}
                className="w-full h-[348px] object-cover  flex items-center justify-center"
              >
                <img
                  alt="popular0"
                  src={`https://admin.paytoua.com/${popular[0]?.img}`}
                  className="w-full h-[348px] object-cover"
                />
              </Squircle>
              <div
                className="popular-truncate-text"
                dangerouslySetInnerHTML={{ __html: popular[0]?.title }}
              ></div>
              <div
                className="regular-truncate-multiline regular_text"
                dangerouslySetInnerHTML={{
                  __html: popular[0]?.text,
                }}
              />
            </Squircle>
          </Link>
          <Link
            to={`/blog/${popular[1]?.link?.replace(/\s+/g, "_")}/${
              popular[1]?.id
            }?${searchParams}`}
          >
            <Squircle className=" h-[500px] forShadow myThirdDIV pt-4 px-4 pb-7 bg-[#F7F7F9] flex flex-col justify-between shadow-lg mt-4 ">
              <Squircle
                cornerRadius={60}
                cornerSmoothing={1}
                className="w-full flex  items-center justify-center h-[236px] mt-4"
              >
                <img
                  className="w-full h-full"
                  alt="popular1"
                  src={`https://admin.paytoua.com/${popular[1]?.img}`}
                />
              </Squircle>
              <div
                className="popular-truncate-text"
                dangerouslySetInnerHTML={{ __html: popular[1]?.title }}
              ></div>
              <div
                className="popular-truncate-multiline"
                dangerouslySetInnerHTML={{
                  __html: popular[1]?.text,
                }}
              ></div>
            </Squircle>
          </Link>
          <Squircle
            cornerRadius={42}
            cornerSmoothing={1}
            className=" h-[400px] forShadow mySecondDIV pt-4 px-4 pb-7 bg-[#F7F7F9] flex flex-col justify-between shadow-lg mt-4 "
          >
            {popular?.slice(2, 7)?.map((item) => {
              return (
                <div className="w-full py-[17px] bg-blue-500 bg-opacity-20 rounded-[30px] items-center justify-center flex">
                  <Link
                    to={`/blog/${item?.link?.replace(/\s+/g, "_")}/${
                      item?.id
                    }?${searchParams}`}
                  >
                    <div
                      className="popular-truncate-multiline_1 regular_text"
                      dangerouslySetInnerHTML={{ __html: item?.title }}
                    ></div>
                  </Link>
                </div>
              );
            })}
          </Squircle>
          <Link
            className="w-full"
            to={`/blog/${popular[7]?.link?.replace(/\s+/g, "_")}/${
              popular[7]?.id
            }?${searchParams}`}
          >
            <Squircle className=" h-[400px] forShadow popText mobile_change p-5 pb-7 bg-[#F7F7F9] flex flex-row justify-between shadow-lg flex-grow md:w-full mt-4">
              <Squircle
                cornerRadius={42}
                cornerSmoothing={1}
                className="h-full object-cover flex items-center justify-center"
              >
                <img
                  alt="popular0"
                  src={`https://admin.paytoua.com/${popular[7]?.img}`}
                  className="w-full h-full object-cover"
                />
              </Squircle>
              <div className="w-[100%]">
                <div
                  className="popular-truncate-text1"
                  dangerouslySetInnerHTML={{ __html: popular[7]?.title }}
                >
                  {/* Как снять денги без карты */}
                </div>
                <div
                  className="popular-truncate-multiline_17"
                  dangerouslySetInnerHTML={{ __html: popular[7]?.text }}
                />
              </div>
            </Squircle>
          </Link>
        </div>
      ) : (
        <>
          <div className=" flex md:flex-row flex-col items-center  space-x-5 w-full ">
            <Link
              className="w-full"
              to={`/blog/${popular[0]?.link?.replace(/\s+/g, "_")}/${
                popular[0]?.id
              }?${searchParams}`}
            >
              <Squircle
                // cornerRadius={60}
                // cornerSmoothing={1}
                className=" h-[500px] forShadow p-5 pb-7 bg-[#F7F7F9] flex flex-col  shadow shadow-lg flex-grow md:w-full"
              >
                <Squircle
                  cornerRadius={42}
                  cornerSmoothing={1}
                  className="w-full h-[348px] object-cover flex items-center justify-center"
                >
                  <img
                    alt="popular0"
                    src={`https://admin.paytoua.com/${popular[0]?.img}`}
                    className="w-full h-[348px] object-cover  popular0"
                  />
                </Squircle>
                <div
                  className="popular-truncate-text "
                  dangerouslySetInnerHTML={{ __html: popular[0]?.title }}
                ></div>
                <div
                  className="regular-truncate-multiline regular_text"
                  dangerouslySetInnerHTML={{
                    __html: popular[0]?.text,
                  }}
                />
              </Squircle>
            </Link>
            <Link
              to={`/blog/${popular[1]?.link?.replace(/\s+/g, "_")}/${
                popular[1]?.id
              }?${searchParams}`}
            >
              <Squircle className="w-[400px] h-[500px] forShadow pt-4 px-4 pb-7 bg-[#F7F7F9] flex flex-col justify-between shadow-lg ">
                <Squircle className="w-full forShadow flex items-center justify-center h-[236px]">
                  <img
                    className="w-full h-full popular1"
                    alt="popular1"
                    src={`https://admin.paytoua.com/${popular[1]?.img}`}
                  />
                </Squircle>
                <div
                  className="popular-truncate-text"
                  dangerouslySetInnerHTML={{ __html: popular[1]?.title }}
                ></div>
                <div
                  className="popular-truncate-multiline regular_text"
                  dangerouslySetInnerHTML={{
                    __html: popular[1]?.text,
                  }}
                ></div>
              </Squircle>
            </Link>
          </div>
          <div className=" flex md:flex-row flex-col  items-center  space-x-5  mt-5">
            <Squircle className="w-[400px] forShadow h-[400px] popText rounded-[30px] pt-4 px-4 pb-7 bg-[#F7F7F9] flex flex-col justify-between shadow-lg ">
              {popular?.slice(2, 7)?.map((item) => {
                return (
                  <div className="w-full popText py-[17px] bg-blue-500 bg-opacity-20 rounded-[30px] items-center justify-center flex">
                    <Link
                      to={`/blog/${item?.link?.replace(/\s+/g, "_")}/${
                        item?.id
                      }?${searchParams}`}
                    >
                      <div
                        className="popular-truncate-multiline_1"
                        dangerouslySetInnerHTML={{ __html: item?.title }}
                      ></div>
                    </Link>
                  </div>
                );
              })}
            </Squircle>
            <Link
              to={`/blog/${popular[7]?.link?.replace(/\s+/g, "_")}/${
                popular[7]?.id
              }?${searchParams}`}
            >
              <Squircle className=" h-[400px] popText forShadow p-5 pb-7 bg-[#F7F7F9] flex flex-row justify-between shadow-lg flex-grow md:w-full">
                <Squircle
                  cornerRadius={42}
                  cornerSmoothing={1}
                  className="w-[30%] h-full object-cover flex items-start justify-center"
                >
                  <img
                    alt="popular0"
                    src={`https://admin.paytoua.com/${popular[7]?.img}`}
                    className="w-full h-full object-cover middle_img"
                  />
                </Squircle>
                <div className="w-[65%]">
                  <div
                    className="popular-truncate-text1"
                    dangerouslySetInnerHTML={{ __html: popular[7]?.title }}
                  ></div>
                  <div
                    className="popular-truncate-multiline_17 max-w-[500px]"
                    dangerouslySetInnerHTML={{ __html: popular[7]?.text }}
                  />
                </div>
              </Squircle>
            </Link>
          </div>
        </>
      )}
    </div>
  );
};
